<template>
  <div>
    <b-row>
      <b-col lg="4" sm="12">
        <span class="my-3 d-block text-primary-dark font-weight-bold"> {{ $t('Graduates') }} </span>
      </b-col>
      <b-col lg="8" sm="12" class="d-inline margin-top-auto">
        <b-row>
          <b-col lg="7" md="8" sm="12" class="mb-2">
            <b-input-group size="sm">
              <b-form-input class="border-right-0 bg-search-graudated" v-model="searchText" @change="onChangeSearch" :placeholder="$t('Search')"></b-form-input>
              <b-input-group-append class="bg-search-graudated"  @click="onChangeSearch">
                <b-input-group-text class="border-left-0 bg-search-graudated">
                  <b-icon icon="search" font-scale="0.8" class="text-danger-light font-weight-bold" />
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col lg="5" md="4" sm="12">
            <b-dropdown id="dropdown-offset" no-caret right variant="white" size="sm" class="w-100 border border-1 rounded bg-search-graudated">
              <template #button-content>
                <span class="mr-3 text-primary-dark">{{ $t('Ordering') }}</span> <b-icon icon="filter" font-scale="1"></b-icon>
              </template>
              <b-dropdown-item href="#" class="text-13" @click="onOrdering('first_name')">{{ $t('Name - ascending') }}</b-dropdown-item>
              <b-dropdown-item href="#" class="text-13" @click="onOrdering('-first_name')">{{ $t('Name - decending') }}</b-dropdown-item>
              <b-dropdown-item href="#" class="text-13" @click="onOrdering('graduate_no')">{{ $t('Graduated no - ascending') }}</b-dropdown-item>
              <b-dropdown-item href="#" class="text-13" @click="onOrdering('-graduate_no')">{{ $t('Graduated no - decending') }}</b-dropdown-item>
              <b-dropdown-item href="#" class="text-13" @click="onOrdering('graduate_at')">{{ $t('Graduated Date - ascending') }}</b-dropdown-item>
              <b-dropdown-item href="#" class="text-13" @click="onOrdering('-graduate_at')">{{ $t('Graduated Date - decending') }}</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-col class="mb-3">
      <b-row class="py-3 px-lg-3">
        <b-col v-if="userProfile && userProfile.id == school.user" lg="3" sm="6" cols="6" class="pr-0">
          <div v-b-modal.modal-add-graduate class="border border-1 rounded w-100  d-flex justify-content-center align-items-center add-img-gallery" style="height: 213px !important;">
            <div>
              <b-icon class="ml-1" scale="2" :title="$t('Add')" style="color:#DBDBDB" icon="plus"/>
              <span class="d-block" style="color:#DBDBDB !important;">{{ $t('Add') }}</span>
            </div>
          </div>
        </b-col>
        <b-col v-for="item in resultItems" :key="item.id" lg="3" sm="6" cols="6" class="mt-5 px-4 mb-3">
          <b-row class="text-center rounded item-graduate d-block">
            <b-col xl="12" lg="12" sm="12" col class="px-0">
              <b-avatar variant="light" size="5rem" class="mt-n5" :src="item.avatar ? item.avatar : item.user_avatar"></b-avatar>
              <b-dropdown v-if="userProfile && userProfile.id == school.user"  size="sm" variant="light" right no-caret class="btn-edit-graduate">
                <template #button-content>
                  <b-icon icon="three-dots-vertical" font-scale="1"></b-icon>
                </template>
                <b-dropdown-item href="#" class="text-14" v-b-modal.modal-edit-graduate @click="onEdit(item)"><b-icon icon="pencil" class="mt-n1 text-13"></b-icon> {{ $t('Edit') }}</b-dropdown-item>
                <b-dropdown-item href="#" class="text-14" v-b-modal.modal-delete-graduate @click="onDelete(item)"><b-icon icon="trash" class="mt-n1 text-13"></b-icon> {{ $t('Delete') }}</b-dropdown-item>
              </b-dropdown>
              <b-badge v-if="item.teacher_status == 1 || !item.teacher_status" pill placement="'right'" variant="warning" v-b-tooltip.hover style="position:absolute;margin-top:-30px;margin-left:-10px" :title="$t('Wait for approval')"><b-icon icon="person-x"></b-icon></b-badge>
            </b-col>
            <b-col sm="12" lg="12" col class="mb-3">
              <div class="text-center">
                <div v-if="item.teacher_id">
                  <router-link class="text-primary-dark font-weight-bold text-13 mt-lg-2 text-item-training text-capitalize" :to="{ name: 'teacherProfileDetail', params: { teacherId: item.teacher_id }}">{{ item.first_name + ' ' + (item.last_name || '')}}</router-link><br>
                </div>
                <div v-else>
                  <span class="text-primary-dark font-weight-bold text-13 mt-lg-2 text-item-training text-capitalize">{{ item.first_name + ' ' + (item.last_name || '')}}</span><br>
                </div>
                <span class="text-danger-light text-12 text-uppercase">{{ item.graduate_no }}</span><br>
                <span class="text-gray-light text-11 mb-2">{{ new Date(item.graduate_at) | dateFormat('DD MMMM YYYY', dateFormatConfig) }}</span><br>
                <b-button v-if="item.website_url" :href="item.website_url" target="_blank" class="font-weight-bold rounded-circle social-graduate btn-light border-0 mt-2"><b-icon icon="globe2" style="margin-left: -3px;"/></b-button>
                <b-button v-if="item.facebook_url" :href="item.facebook_url" target="_blank" class="font-weight-bold rounded-circle social-graduate btn-light border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'facebook-f']" class="text-primary-dark"/></b-button>
                <b-button v-if="item.instagram_url" :href="item.instagram_url" target="_blank" class="font-weight-bold rounded-circle social-graduate btn-light border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'instagram']" class="text-primary-dark"/></b-button>
                <b-button v-if="item.twitter_url" :href="item.twitter_url" target="_blank" class="font-weight-bold rounded-circle social-graduate btn-light border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'twitter']" class="text-primary-dark"/></b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-row v-if="resultItems.length == 0" class="text-center justify-content-center">
      <span class="text-14">{{ $t('No results') }}</span>
    </b-row>
    <b-modal ref="modal-delete-graduate" id="modal-delete-graduate" hide-footer centered :title="$t('Delete Graduates')">
      <p>{{ $t('Do you want to delete {name}', { name: formDelete.name }) }}</p>
      <div class="w-100 justify-content-center d-flex">
        <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-delete-graduate')">
          {{ $t('Cancel') }}
        </b-button>
        <b-button pill type="submit" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark" @click="onSubmitDelete">
          <b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>
          {{ $t('Delete') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal
      size="lg"
      ref="modal-edit-graduate"
      id="modal-edit-graduate"
      hide-footer
      centered
      @hidden="setForm"
      :title="$t('Edit Graduates')">
       <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <b-form ref="form" @submit.stop.prevent="handleSubmit(onSubmitEdit)">
          <div class="justify-content-center d-flex">
           <b-avatar variant="light" class="avatar-graduate" text="Add Image" :src="avatar" size="150px">
            <template #badge>
              <div class="upload-btn-wrapper">
                <b-icon icon="camera" font-scale="0.5" class="btn-danger-light btn-file-graduate text-white mt-n2" style="vertical-align:0.2em"></b-icon>
                <input type="file" name="avatar" :v-model="form.avatar" @change="onFileChange"/>
              </div>
            </template>
          </b-avatar>
          </div>
          <br>
          <span class="text-18 font-weight-bold text-primary-dark">{{ $t('Basic info') }}</span>
          <b-row>
            <b-col class="px-0 pr-0 col-sm-12 col-lg-6">
              <validation-provider
                  :name="$t('Name')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group class="d-inline col-12 pr-2 font-weight-bold text-primary-dark" for="input-name-graduated" :label="$t('Name')+'*'">
                  <b-form-input id="input-name-graduated" type="text" class="input-auth" v-model="form.first_name" :placeholder="$t('Name')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              </b-col>
              <b-col class="px-0 pl-2 col-sm-12 col-lg-6">
              <validation-provider
                  :name="$t('Lastname')"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group class="d-inline col-12 pl-0 font-weight-bold text-primary-dark" for="input-lastname-graduated" :label="$t('Lastname')">
                  <b-form-input id="input-lastname-graduated" type="text" class="input-auth" v-model="form.last_name" :placeholder="$t('Lastname')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="px-0 col-sm-12 col-lg-6">
            <validation-provider
                :name="$t('Email')"
                rules="required"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group class="d-inline col-12 pr-2 font-weight-bold text-primary-dark" for="input-email-graduated" :label="$t('Email')+'*'">
                <b-form-input id="input-email-graduated" type="email" class="input-auth" v-model="form.email" :placeholder="$t('Email')"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>
            <b-col class="px-0 col-sm-12 col-lg-6">
            <validation-provider
                :name="$t('Phone number')"
                rules="numeric"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group class="d-inline col-12 pl-2 font-weight-bold text-primary-dark" for="input-phonenumber-graduated" :label="$t('Phone number')">
                <b-form-input id="input-phonenumber-graduated" type="text" class="input-auth" v-model="form.phone_number" :placeholder="$t('Phone number')"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>
          </b-row>
           <b-row>
            <!-- <b-col class="px-0 col-sm-12 col-lg-6">
            <validation-provider
                :name="$t('Graduate No')"
                rules="required"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group class="d-inline col-12 pr-2 font-weight-bold text-primary-dark" for="input-graduate-no" :label="$t('Graduate No')+'*'">
                <b-form-input id="input-graudate-no" type="text" class="input-auth" v-model="form.graduate_no" :placeholder="$t('Graduate No')"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col> -->
            <b-col class="px-0 col-sm-12 pl-2 col-lg-6">
            <validation-provider
                :name="$t('Graduation Date')"
                rules="required"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group class="d-inline col-12 pl-2 font-weight-bold text-primary-dark" for="input-edit-graduate-date" :label="$t('Graduation Date')+'*'">
                <b-form-datepicker
                    id="input-edit-graduate-date"
                    placeholder="YYYY-mm-dd"
                    v-model="form.graduate_at"
                    hideHeader
                    class="mb-2"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    @context="onContextEditDate"
                  >
                  </b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>
          </b-row>
          <span class="text-18 font-weight-bold text-primary-dark">{{ $t('Social media') }}</span><br>
          <validation-provider
              :name="$t('Website')"
              rules=""
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-website-graduated" :label="$t('Website')">
              <b-form-input id="input-website-graduated" type="text" class="input-auth" v-model="form.website_url" :placeholder="$t('Website')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Facebook URL')"
              rules=""
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-facebook-graduated" :label="$t('Facebook URL')">
              <b-form-input id="input-facebook-graduated" type="text" class="input-auth" v-model="form.facebook_url" :placeholder="$t('Facebook URL')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Twitter URL')"
              rules=""
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-twitter-graduated" :label="$t('Twitter URL')">
              <b-form-input id="input-twitter-graduated" type="text" class="input-auth" v-model="form.twitter_url" :placeholder="$t('Twitter URL')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Instagram URL')"
              rules=""
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-instagram-graduated" :label="$t('Instagram URL')">
              <b-form-input id="input-instagram-graduated" type="text" class="input-auth" v-model="form.instagram_url" :placeholder="$t('Instagram URL')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <div class="row">
            <b-col sm="12" lg="12" class="d-flex justify-content-center">
              <b-button pill @click="setForm" class="mt-2  px-5 my-4 btn-light font-weight-bold d-inline">{{ $t('Cancel') }}</b-button>
              <b-button pill type="submit" :disabled="isLoadingForm" class="d-inline ml-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark"><b-spinner small class="mr-3" v-if="isLoadingForm"  label="Loading..."/>{{ $t('Save') }}</b-button>
            </b-col>
          </div>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <b-modal
      size="lg"
      ref="modal-add-graduate"
      id="modal-add-graduate"
      hide-footer
      body-class="pt-0"
      centered
      @hidden="setForm"
      :title="$t('Add Graduates')">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <b-form ref="form" @submit.stop.prevent="handleSubmit(onSubmit)">
          <b-form-group class="d-inline w-100 font-weight-bold text-primary-dark" for="input-twitter-graduated" :label="$t('Number of graduates')">
            <!-- <b-form-input id="input-number-form" type="number" class="input-auth" maxlength="2" v-model="numberOfGraduates" :placeholder="$t('Number of graduates')"></b-form-input> -->
            <b-form-select id="input-number-form" v-model="numberOfGraduates" :options="optionGraduates"></b-form-select>
          </b-form-group>
          <b-form-group>
            <label for="apply_for" class="text-primary-dark font-weight-600">{{ $t('Applying for') }} <span class="text-danger-light">*</span></label>
            <multiselect
              v-model="form.teacher_apply_type"
              disabled
              :options="teacher_type_apply_options"
              :multiple="true"
              :placeholder="$t('Applying for')"
              :selectedLabel="$t('Selected')"
              :deselectLabel="$t('Press enter to remove')"
              :selectLabel="$t('Press enter to select')"
              label="teacher_apply_type_name"
              track-by="teacher_apply_type"
              ></multiselect>
          </b-form-group>
          <b-form-group>
            <div>
              <label for="level_of_registration" class="text-primary-dark font-weight-600">{{ $t('Level of Registration') }} <span class="text-danger-light">*</span></label>
              <b-form-checkbox v-model="levelTypechecked" disabled name="check-button" switch class="ml-2 text-right d-inline">
                <b>{{ levelTypechecked ? $t('Lifetime') : $t('Annual') }}</b>
              </b-form-checkbox>
            </div>
            <multiselect
              v-model="form.teacher_level"
              :options="teacher_level_options"
              :multiple="true"
              :placeholder="$t('Level of Registration')"
              :selectedLabel="$t('Selected')"
              :deselectLabel="$t('Press enter to remove')"
              :selectLabel="$t('Press enter to select')"
              label="teacher_level_name"
              track-by="teacher_level"
              ></multiselect>
          </b-form-group>
          <!-- <b-form-group>
            <label for="experience_yoga_teaching" class="text-primary-dark font-weight-600">{{ $t('How long have you been teaching yoga?') }} <span class="text-danger-light">*</span></label>
            <b-form-select id="experience_yoga_teaching" v-model="form.teacher_experience" :options="optionForm.teacher_experience_years" value-field="id" text-field="name">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group>
            <label for="after_graduated_teaching" class="text-primary-dark font-weight-600">{{ $t('Have you been teaching yoga classes at least 3,000 - 4,500 hours after graduated?') }} <span class="text-danger-light">*</span></label>
            <b-form-select id="after_graduated_teaching"  v-model="form.is_teacher_been_teaching_least" :options="optionForm.is_teacher_been_teaching_least" value-field="value" text-field="name">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group> -->
          <b-form-group>
            <label for="yoga_style" class="text-primary-dark font-weight-600">{{ $t('Style of Yoga') }} <span class="text-danger-light">*</span></label>
              <multiselect
              v-model="form.style_of_yoga"
              :options="teacher_style_of_yoga_options"
              :multiple="true"
              :placeholder="$t('Style of Yoga')"
              :selectedLabel="$t('Selected')"
              :deselectLabel="$t('Press enter to remove')"
              :selectLabel="$t('Press enter to select')"
              label="style_of_yoga_name"
              track-by="style_of_yoga"
              ></multiselect>
          </b-form-group>
          <b-row>
          <b-col class="col-sm-12 col-lg-6">
            <validation-provider
              :name="$t('Graduation Date')"
              rules="required"
              autocomplete="off"
              v-slot="{errors}"
            >
              <b-form-group class="d-inline w-100 font-weight-bold text-primary-dark" for="input-graduate-date" :label="$t('Graduation Date')+'*'">
                <b-form-datepicker
                    :id="'input-graduate-date'"
                    placeholder="YYYY-mm-dd"
                    v-model="form.graduate_at"
                    hideHeader
                    class="mb-2"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    @context="onContextDate"
                  >
                  </b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          </b-row>
          <div class="" v-for="(f, index) in multiForm" :key="index">
          <div class="justify-content-center d-flex">
           <b-avatar variant="light" class="avatar-graduate" text="Add Image" :src="f.url_avatar" size="150px">
            <template #badge>
              <div class="upload-btn-wrapper">
                <b-icon icon="camera" font-scale="0.5" class="btn-danger-light btn-file-graduate text-white mt-n2" style="vertical-align:0.2em"></b-icon>
                <input type="file" name="avatar" :id="'avatar_'+index" :v-model="f.avatar" :key="index" @change="onFileChange"/>
              </div>
            </template>
          </b-avatar>
          </div>
          <br>
          <span class="text-18 font-weight-bold text-primary-dark">{{ $t('Basic info') }} <b-badge pill variant="primary">{{ index + 1 }}</b-badge></span>
          <b-row>
            <b-col class="px-0 pr-0 col-sm-12 col-lg-6">
              <validation-provider
                  :name="$t('Name')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group class="d-inline col-12 pr-2 font-weight-bold text-primary-dark" :for="'input-name-graduated'+index" :label="$t('Name')+'*'">
                  <b-form-input :id="'input-name-graduated'+index" type="text" :key="index" class="input-auth" v-model="f.first_name" :placeholder="$t('Name')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              </b-col>
              <b-col class="px-0 pl-2 col-sm-12 col-lg-6">
              <validation-provider
                  :name="$t('Lastname')"
                  autocomplete="off"
                  v-slot="{errors}"
                  rules="required"
                >
                <b-form-group class="d-inline col-12 pl-0 font-weight-bold text-primary-dark" :for="'input-lastname-graduated'+index" :label="$t('Lastname')+'*'">
                  <b-form-input :id="'input-lastname-graduated'+index" type="text" class="input-auth" v-model="f.last_name" :key="index" :placeholder="$t('Lastname')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="px-0 col-sm-12 col-lg-6">
            <validation-provider
                :name="$t('Email')"
                rules="required"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group class="d-inline col-12 pr-2 font-weight-bold text-primary-dark" :for="'input-email-graduated'+index" :label="$t('Email')+'*'">
                <b-form-input :id="'input-email-graduated'+index" type="email" class="input-auth" v-model="f.email" :key="index" :placeholder="$t('Email')"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>
            <b-col class="px-0 col-sm-12 col-lg-6">
            <validation-provider
                :name="$t('Phone number')"
                rules="numeric"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group class="d-inline col-12 pl-2 font-weight-bold text-primary-dark" :for="'input-phonenumber-graduated'+index" :label="$t('Phone number')">
                <b-form-input :id="'input-phonenumber-graduated'+index" type="text" class="input-auth" v-model="f.phone_number"  :key="index" :placeholder="$t('Phone number')"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>
          </b-row>
          <span class="text-18 font-weight-bold text-primary-dark">{{ $t('Social media') }}</span><br>
          <validation-provider
              :name="$t('Website')"
              rules=""
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" :for="'input-website-graduated'+index" :label="$t('Website')">
              <b-form-input :id="'input-website-graduated'+index" type="text" class="input-auth" v-model="f.website_url" :key="index" :placeholder="$t('Website')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Facebook URL')"
              rules=""
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" :for="'input-facebook-graduated'+index" :label="$t('Facebook URL')">
              <b-form-input :id="'input-facebook-graduated'+index" type="text" class="input-auth" v-model="f.facebook_url" :key="index" :placeholder="$t('Facebook URL')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Twitter URL')"
              rules=""
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-twitter-graduated" :label="$t('Twitter URL')">
              <b-form-input id="input-twitter-graduated" type="text" class="input-auth" v-model="f.twitter_url" :key="index" :placeholder="$t('Twitter URL')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Instagram URL')"
              rules=""
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" :for="'input-instagram-graduated'+index" :label="$t('Instagram URL')">
              <b-form-input :id="'input-instagram-graduated'+index" type="text" class="input-auth" v-model="f.instagram_url" :key="index" :placeholder="$t('Instagram URL')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          </div>
          <div class="row">
            <b-col sm="12" lg="12" class="d-flex justify-content-center">
              <b-button pill @click="setForm" class="mt-2  px-5 my-4 btn-light font-weight-bold d-inline">{{ $t('Cancel') }}</b-button>
              <b-button pill type="submit" :disabled="isLoadingForm" class="d-inline ml-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark"><b-spinner small class="mr-3" v-if="isLoadingForm"  label="Loading..."/>{{ $t('Save') }}</b-button>
            </b-col>
          </div>
        </b-form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import Api from '../../api/api'
export default {
  name: 'SchoolGraduate',
  props: {
    resultItems: Array,
    school: Object,
    isLoadingForm: Boolean,
    closeModalForm: Boolean
  },
  data () {
    return {
      isLoading: false,
      avatar: null,
      form: {
        id: null,
        school: null,
        avatar: null,
        url_avatar: null,
        teacher_apply_type: [{
          teacher_apply_type: 13,
          teacher_apply_type_name: 'Certified Yoga Teacher (CYT)'
        }],
        teacher_level: [],
        teacher_experience: 1,
        is_teacher_been_teaching_least: false,
        style_of_yoga: [],
        user: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        website_url: null,
        facebook_url: null,
        twitter_url: null,
        instagram_url: null,
        graduate_no: null,
        graduate_at: this.$options.filters.dateFormat(new Date(), 'YYYY-MM-DD'),
        is_active: true
      },
      levelTypechecked: true,
      numberOfGraduates: 3,
      optionForm: {
        teacher_background: [],
        teacher_experience_years: [],
        teacher_levels: [],
        teacher_type_apply: [],
        teacher_types: [],
        teacher_style_of_yoga: [],
        is_teacher_been_teaching_least: [
          { value: true, name: this.$t('Yes') },
          { value: false, name: this.$t('No') }
        ]
      },
      teacher_type_apply_options: [],
      teacher_level_options: [],
      teacher_level_temp_options: [],
      teacher_style_of_yoga_options: [],
      searchText: '',
      ordering: '',
      formDelete: {
        id: null,
        name: ''
      },
      multiForm: [],
      optionGraduates: [
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
        { value: 11, text: 11 },
        { value: 12, text: 12 },
        { value: 13, text: 13 },
        { value: 14, text: 14 },
        { value: 15, text: 15 },
        { value: 16, text: 16 },
        { value: 17, text: 17 },
        { value: 18, text: 18 },
        { value: 19, text: 19 },
        { value: 20, text: 20 },
        { value: 21, text: 21 },
        { value: 22, text: 22 },
        { value: 23, text: 23 },
        { value: 24, text: 24 },
        { value: 25, text: 25 },
        { value: 26, text: 26 },
        { value: 27, text: 27 },
        { value: 28, text: 28 },
        { value: 29, text: 29 },
        { value: 30, text: 30 }
      ]
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    }
  },
  watch: {
    closeModalForm: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.setForm()
      }
    },
    numberOfGraduates (newNumberOfGraduates, oldNumberOfGraduates) {
      if (newNumberOfGraduates) {
        this.resetForm()
      }
    },
    form: {
      handler (val) {
        this.multiForm.forEach(val => {
          val.teacher_level = this.form.teacher_level
          val.style_of_yoga = this.form.style_of_yoga
          val.teacher_apply_type = this.form.teacher_apply_type
          val.graduate_at = this.form.graduate_at
        })
      },
      deep: true
    }
  },
  mounted () {
    this.form.school = this.school.id
    this.form.graduate_at = this.$options.filters.dateFormat(new Date(), 'YYYY-MM-DD')
    console.log(this.form.graduate_at)
    this.formDataTeacher()
    this.resetForm()
  },
  methods: {
    onContextDate (val) {
      this.form.graduate_at = val.activeYMD
      document.getElementById('input-graduate-date__value_').innerHTML = this.form.graduate_at
    },
    onContextEditDate () {
      this.form.graduate_at = this.$options.filters.dateFormat(new Date(this.form.graduate_at), 'YYYY-MM-DD')
      document.getElementById('input-edit-graduate-date__value_').innerHTML = this.form.graduate_at
    },
    setForm () {
      this.$refs['modal-add-graduate'].hide()
      this.$refs['modal-edit-graduate'].hide()
      requestAnimationFrame(() => {
        this.avatar = null
        this.form.school = this.school.id
        this.form.avatar = null
        this.form.user = null
        this.form.first_name = null
        this.form.last_name = null
        this.form.email = null
        this.form.phone_number = null
        this.form.website_url = null
        this.form.facebook_url = null
        this.form.twitter_url = null
        this.form.instagram_url = null
        this.form.graduate_no = null
        this.form.graduate_at = this.$options.filters.dateFormat(new Date(), 'YYYY-MM-DD')
      })
      this.resetForm()
      console.log(this.form)
    },
    onFileChange (e) {
      const file = e.target.files[0]
      const id = e.target.id.split('_')
      if (id.length === 1) {
        this.avatar = URL.createObjectURL(file)
        this.form.avatar = file
      } else {
        this.multiForm[id[1]].url_avatar = URL.createObjectURL(file)
        this.multiForm[id[1]].avatar = file
      }
    },
    onSubmit () {
      this.closeModalForm = false
      this.$refs.form.validate().then(result => {
        if (result) {
          this.$emit('addGraduate', this.multiForm)
          this.resetForm()
        }
      })
    },
    onEdit (graduate) {
      this.form = { ...this.form, ...graduate }
      this.form.avatar = null
      this.avatar = graduate.avatar
    },
    onSubmitEdit () {
      this.closeModalForm = false
      this.$refs.form.validate().then(result => {
        if (result) {
          this.$emit('editGraduate', this.form)
        }
      })
    },
    onDelete (graduate) {
      this.formDelete.id = graduate.id
      this.formDelete.name = graduate.first_name + ' ' + (graduate.last_name || '')
    },
    onSubmitDelete () {
      this.$emit('onDeleteGraduate', this.formDelete)
      this.$refs['modal-delete-graduate'].hide()
    },
    onOrdering (order) {
      this.ordering = order
      this.onChangeSearch()
    },
    onChangeSearch () {
      this.$emit('onSearchGraduate', { searchText: this.searchText, ordering: this.ordering })
    },
    formDataTeacher () {
      Api.formDataTeacher().then(response => {
        if (response.data) {
          this.optionForm = { ...this.optionForm, ...response.data }
          const teacherApplyTypeOptions = []
          const teacherLevelOptions = []
          const teacherStyleOfYogaOptions = []
          this.optionForm.teacher_type_apply.forEach(item => {
            teacherApplyTypeOptions.push({
              teacher_apply_type: item.id,
              teacher_apply_type_name: item.name
            })
          })
          this.teacher_type_apply_options = teacherApplyTypeOptions
          this.optionForm.teacher_levels.forEach(item => {
            teacherLevelOptions.push({
              teacher_level: item.id,
              teacher_level_name: item.name,
              level_type: item.level_type
            })
          })
          this.teacher_level_options = teacherLevelOptions
          this.teacher_level_temp_options = teacherLevelOptions
          if (this.form.teacher_level.length > 0) {
            if (this.form.teacher_level[0].level_type === 'Lifetime') {
              this.setOptionTeacherLevel(true)
            } else {
              this.levelTypechecked = false
              this.setOptionTeacherLevel(false)
            }
          } else {
            this.setOptionTeacherLevel(true)
          }
          this.optionForm.teacher_style_of_yoga.forEach(item => {
            teacherStyleOfYogaOptions.push({
              style_of_yoga: item.id,
              style_of_yoga_name: item.name
            })
          })
          this.teacher_style_of_yoga_options = teacherStyleOfYogaOptions
        }
      })
    },
    setOptionTeacherLevel (levelTypechecked) {
      if (levelTypechecked) {
        this.teacher_level_options = this.teacher_level_temp_options.filter(level => level.level_type === 'Lifetime')
        this.form.teacher_level = this.form.teacher_level.filter(level => level.level_type === 'Lifetime')
      } else {
        this.teacher_level_options = this.teacher_level_temp_options.filter(level => level.level_type === 'Annual')
        this.form.teacher_level = this.form.teacher_level.filter(level => level.level_type === 'Annual')
      }
    },
    resetForm () {
      this.form.graduate_at = null
      this.form.style_of_yoga = []
      this.form.teacher_level = []
      this.multiForm = []
      const tempForm = []
      for (var i = 0; i < this.numberOfGraduates; i++) {
        tempForm.push({
          id: null,
          school: this.school.id,
          avatar: null,
          url_avatar: null,
          teacher_experience: 1,
          is_teacher_been_teaching_least: false,
          teacher_level: this.form.teacher_level,
          style_of_yoga: this.form.style_of_yoga,
          teacher_apply_type: this.form.teacher_apply_type,
          user: null,
          first_name: null,
          last_name: null,
          email: null,
          phone_number: null,
          website_url: null,
          facebook_url: null,
          twitter_url: null,
          instagram_url: null,
          graduate_no: null,
          graduate_at: this.$options.filters.dateFormat(new Date(), 'YYYY-MM-DD'),
          is_active: true
        })
      }
      this.multiForm = tempForm
    }
  }
}
</script>
<style>
  .item-graduate {
    background-color: #F5F7FA;
    min-height: 165px;
  }
  .social-graduate {
    height: 37px;
    width: 37px;
    background-color: #e4ebf7;
  }
  .btn-edit-graduate {
    float: right;
    right: 0;
    position: absolute;
  }
  .bg-search-graudated {
    background-color: #FAFAFA;
    border-color: #DBDBDB;
  }
  .avatar-graduate > span.b-avatar-badge {
    min-width: 47px !important;
    min-height: 40px !important;
    padding:0px;
    background-color: #F04B4C !important;
    border-color: #F04B4C !important;
  }
  .avatar-graduate > span.b-avatar-text {
    font-size: 0.9rem !important;
  }
  .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-file-graduate {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>
