<template>
  <div>
    <div style="width: 100%;">
    <b-row class="px-2 waper-event text-gray-light" style="display:block; overflow-x: auto; white-space: nowrap;">
      <b-col cols="1" v-for="month in months" :key="month.key" v-bind:class="[month.selected ? 'active' : '', 'item-event-month py-2 text-center']" @click="selectMonth(month)">
        <span class="event-year">{{ new Date(month.date) | dateFormat('YYYY') }}</span><br><span class="event-month">{{  new Date(month.date) |  dateFormat('MMM', dateFormatConfig) }}</span>
      </b-col>
    </b-row>
    <b-row class="justify-content-center d-flex my-3">
      <div>
        <b-button v-if="userProfile && userProfile.id == school.user" :to="{name: 'addEvent', query: { type: type == 'teacher' ? 'teacher' : 'school' } }" pill class="bth btn-danger-light font-weight-bold text-14 px-3 "><b-icon icon="plus" class="mr-2" variant="light"></b-icon>{{ $t('Add Training & Event') }}</b-button>
      </div>
    </b-row>
    </div>
    <span class="my-3 d-block text-primary-dark font-weight-bold"> {{ $t('Event Upcoming') }} </span>
    <b-col class="mb-3">
      <b-row v-for="item in resultItems" :key="item.id" class="mt-2 py-3 px-lg-3 rounded row-item-event border-bottom">
        <b-dropdown v-if="userProfile && userProfile.id == school.user" size="sm" variant="white" right no-caret class="btn-edit-graduate" style="z-index:100">
          <template #button-content>
            <b-icon icon="three-dots-vertical" font-scale="1"></b-icon>
          </template>
          <b-dropdown-item-button class="text-14" @click="editEvent(item.id)"><b-icon icon="pencil" class="mt-n1 text-13"></b-icon> {{ $t('Edit') }}</b-dropdown-item-button>
          <b-dropdown-item-button class="text-14" @click="deleteItem(item, false)"><b-icon icon="trash" class="mt-n1 text-13"></b-icon> {{ $t('Delete') }}</b-dropdown-item-button>
        </b-dropdown>
        <b-col lg="6" sm="12" @click="eventDetail(item.id)">
          <b-row>
            <b-col xl="6" lg="6" sm="6" col class="px-0">
              <b-img rounded height="120px" class="d-sm-block d-lg-none d-md-none d-xl-none" :src="item.image_small"></b-img>
              <b-img rounded width="175px" class="d-none d-lg-block d-md-block d-xl-block" :src="item.image_small"></b-img>
            </b-col>
            <b-col sm="6" lg="6" col class="float-left d-flex align-items-center pl-lg-0 pl-sm-0">
              <div class="w-100">
                <span v-if="item.region_name" class="text-danger-light mt-lg-2 text-item-training text-12">{{ (item.region_name || '') +', '+ (item.region_country_name || '') }}</span>
                <span v-if="!item.region_name" class="text-danger-light mt-lg-2 text-item-training">-</span>
                <br><span class="text-primary-light">{{ item.subject }}</span><br>
                <span class="text-danger-light d-block text-item-training font-weight-bold float-bottom mt-2 pr-sm-0">${{ item.price | numeral('0,0.00') }}</span>
                <b-button pill class="d-none btn-danger-light font-weight-bold d-lg-none d-md-none"><span class="text-12"><b-icon icon="heart-fill" class="mr-1"></b-icon> Interested</span></b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6" sm="0" class="border-left d-none d-lg-block">
          <b-row class="text-11 h-100  d-flex align-items-center">
            <b-col cols="4">
              <span class="text-primary-dark d-block">{{  new Date(item.start_date) |  dateFormat('MMM DD', dateFormatConfig) }} - {{  new Date(item.end_date) | dateFormat('MMM DD', dateFormatConfig) }}</span>
              <span class="mt-2 text-primary-dark text-12 font-weight-bold d-block">{{  new Date('1999-01-01T'+ item.start_time) | dateFormat('HH:mm A', dateFormatConfig) }}</span>
              <span class="text-gray-light">- {{  new Date('1999-01-01T'+ item.end_time) | dateFormat('HH:mm A', dateFormatConfig) }}</span>
            </b-col>
            <b-col cols="4" class="px-0">
              <b-row>
                <b-col cols="4" class="px-0 text-center">
                  <b-avatar variant="light" size="2.5rem"></b-avatar>
                </b-col>
                <b-col cols="8" class="pl-0">
                  <span class="text-primary-dark font-weight-bold d-block text-12">{{ item.instructors_name }}</span>
                  <span class="text-gray-light">{{ $t('Instructor') }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" class="pr-0">
              <b-button pill class="d-none btn-outline-danger-light font-weight-bold"><span class="text-12 text-danger-light"><b-icon icon="heart-fill" class="mr-1"></b-icon> Interest</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="resultItems.length == 0" class="mt-2 py-3 px-lg-3 justify-content-center">
        <span class="text-14">{{ $t('No results') }}</span>
      </b-row>
    </b-col>
    <span class="my-4 text-primary-dark font-weight-bold"> {{ $t('Past Event') }} </span>
      <b-col>
        <b-row v-for="item in pastResultItems" :key="item.id" class="mt-2 py-3 px-lg-3 rounded row-item-event border-bottom">
        <b-dropdown v-if="userProfile && userProfile.id == school.user" size="sm" variant="white" right no-caret class="btn-edit-graduate" style="z-index:100">
          <template #button-content>
            <b-icon icon="three-dots-vertical" font-scale="1"></b-icon>
          </template>
          <b-dropdown-item-button class="text-14" @click="editEvent(item.id)"><b-icon icon="pencil" class="mt-n1 text-13"></b-icon> {{ $t('Edit') }}</b-dropdown-item-button>
          <b-dropdown-item-button class="text-14" @click="deleteItem(item, true)"><b-icon icon="trash" class="mt-n1 text-13"></b-icon> {{ $t('Delete') }}</b-dropdown-item-button>
        </b-dropdown>
          <b-col lg="6" sm="12" @click="eventDetail(item.id)">
            <b-row>
              <b-col xl="6" lg="6" sm="6" col class="px-0">
                <b-img rounded height="90px" class="d-sm-block d-lg-none d-md-none d-xl-none" :src="item.image_small"></b-img>
                <b-img rounded width="175px" class="d-none d-lg-block d-md-block d-xl-block" :src="item.image_small"></b-img>
              </b-col>
              <b-col sm="6" lg="6" col class="float-left d-flex align-items-center pl-lg-0 pl-sm-0">
                <div class="w-100">
                  <span v-if="item.region_name" class="text-danger-light mt-lg-2 text-item-training text-12 d-lg-block d-md-block d-xl-block d-none">{{ item.region_name +', '+ item.region_country_name }}</span>
                  <span v-if="!item.region_name" class="text-danger-light mt-lg-2 text-item-training d-lg-block d-md-block d-xl-block d-none">-</span>
                  <span class="text-primary-light">{{ item.subject }}</span><br>
                  <div class="d-sm-block d-lg-none d-md-none d-xl-none text-8">
                    <span class="text-gray-light d-block">{{  new Date(item.start_date) |  dateFormat('MMM DD', dateFormatConfig) }} - {{  new Date(item.end_date) | dateFormat('MMM DD', dateFormatConfig) }} | {{  new Date('1999-01-01T'+ item.start_time) | dateFormat('HH:mm A', dateFormatConfig) }} - {{  new Date('1999-01-01T'+ item.end_time) | dateFormat('HH:mm A', dateFormatConfig) }}</span>
                  </div>
                  <span class="text-danger-light d-block text-item-training font-weight-bold float-bottom mt-2 pr-sm-0 text-12">{{ item.currency }} {{ item.price | numeral('0,0.00') }}</span>
                  <b-button pill class="d-none btn-danger-light font-weight-bold d-lg-none d-md-none pt-0 pb-1"><span class="text-12"><b-icon icon="heart-fill" class="mr-1"></b-icon> Interested</span></b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6" sm="0" class="border-left d-none d-lg-block">
            <b-row class="text-11  h-100  d-flex align-items-center">
              <b-col cols="4">
                <span class="text-primary-dark d-block">{{  new Date(item.start_date) |  dateFormat('MMM DD', dateFormatConfig) }} - {{  new Date(item.end_date) | dateFormat('MMM DD', dateFormatConfig) }}</span>
                <span class="mt-2 text-primary-dark text-12 font-weight-bold d-block">{{  new Date('1999-01-01T'+ item.start_time) | dateFormat('HH:mm A', dateFormatConfig) }}</span>
                <span class="text-gray-light">- {{  new Date('1999-01-01T'+ item.end_time) | dateFormat('HH:mm A', dateFormatConfig) }}</span>
              </b-col>
              <b-col cols="4" class="px-0">
                <b-row>
                  <b-col cols="4" class="px-0 text-center">
                    <b-avatar variant="light" size="2.5rem"></b-avatar>
                  </b-col>
                  <b-col cols="8" class="pl-0">
                    <span class="text-primary-dark font-weight-bold d-block text-12">{{ item.instructors_name }}</span>
                    <span class="text-gray-light">{{ $t('Instructor') }}</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4" class="pr-0">
                <b-button pill class="d-none btn-outline-danger-light font-weight-bold"><span class="text-12 text-danger-light"><b-icon icon="heart-fill" class="mr-1"></b-icon> Interest</span></b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-if="pastResultItems.length == 0" class="mt-2 py-3 px-lg-3 justify-content-center">
          <span class="text-14">{{ $t('No results') }}</span>
        </b-row>
    </b-col>
    <b-row class="text-center d-none">
      <span>{{ $t('No results') }}</span>
    </b-row>
    <b-modal ref="modal-delete-event" id="modal-delete-event" hide-footer centered :title="$t('Delete Event')">
      <p>{{ $t('Do you want to delete {name}', { name: formDelete.subject }) }}</p>
      <div class="w-100 justify-content-center d-flex">
        <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-delete-event')">
          {{ $t('Cancel') }}
        </b-button>
        <b-button pill type="submit" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark" @click="onSubmitDelete">
          <b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>
          {{ $t('Delete') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'SchoolEvent',
  props: {
    resultItems: Array,
    pastResultItems: Array,
    isLoading: Boolean,
    school: Object,
    type: String
  },
  data () {
    return {
      eventItemMonth: 12,
      temDate: new Date(),
      currentDate: new Date(),
      monthList: {
        year: 2000,
        month: 'Dec'
      },
      months: [],
      items: [],
      selectedMonth: 0,
      formDelete: {
        id: null,
        subject: '',
        isPast: false
      }
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    }
  },
  mounted () {
    this.months = [
      {
        date: new Date(),
        key: 0,
        selected: true
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 1),
        key: 1,
        selected: false
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 2),
        key: 2,
        selected: false
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 3),
        key: 3,
        selected: false
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 4),
        key: 4,
        selected: false
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 5),
        key: 5,
        selected: false
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 6),
        key: 6,
        selected: false
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 7),
        key: 7,
        selected: false
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 8),
        key: 8,
        selected: false
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 9),
        key: 9,
        selected: false
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 10),
        key: 10,
        selected: false
      },
      {
        date: new Date().setMonth(this.currentDate.getMonth() + 11),
        key: 11,
        selected: false
      }
    ]
  },
  methods: {
    eventDetail (id) {
      this.$router.push({ name: 'eventDetail', params: { eventId: id } })
    },
    selectMonth (month) {
      this.selectedMonth = month.key
      this.months.forEach(n => {
        if (n.key === month.key) {
          n.selected = true
        } else {
          n.selected = false
        }
      })
      this.$emit('onChangeMonthEvent', { date: month.date })
    },
    editEvent (id) {
      this.$router.push({ name: 'editEvent', params: { eventId: id } })
    },
    deleteItem (item, isPast) {
      this.formDelete.id = item.id
      this.formDelete.subject = item.subject
      this.formDelete.isPast = isPast
      this.$refs['modal-delete-event'].show()
    },
    onSubmitDelete (item, isPast) {
      this.$emit('onDeleteEvent', { id: this.formDelete.id, isPast: this.formDelete.isPast })
      this.$refs['modal-delete-event'].hide()
    }
  }
}
</script>
<style >
  .border-primary-dark {
    border-color: #3B6098 !important;
    background-color: #F5F7FA;
  }
  .item-event-month {
    min-width: 70px;
    display: inline-block;
    float: none;
  }
  .item-event-month.active {
    cursor: pointer;
    background-color: #F5F7FA;
    border: 1px solid #3B6098 !important;
    border-radius: 5px;
  }
  .item-event-month.active span.event-year {
    color:#F04B4C !important;
  }
  .item-event-month:active span.event-month {
    color:#3B6098 !important;
  }
  .item-event-month:hover {
    cursor: pointer;
    background-color: #F5F7FA;
    border: 1px solid #3B6098 !important;
    border-radius: 5px;
  }
  .item-event-month:hover .event-year {
    color:#F04B4C !important;
  }
  .item-event-month:hover .event-month {
    color:#3B6098 !important;
  }
  .row-item-event:hover {
    background-color: #F5F7FA;
    cursor: pointer;
  }
  .waper-event::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .waper-event {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
</style>
