<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false">
      <b-row>
        <b-col cols="12" class="p-0">
          <div v-if="!school.doc_cover" class="cover-profile-school img-fluid rounded d-block">
            <div class="caption">
              <div class="inner_caption">
                <div class="align_caption">
                  <h2 class="title section-header-title text-uppercase">{{ $t('WORLD YOGA ALLIANCE') }}</h2>
                  <p class="subtitle text-primary-dark mt-3">{{ $t('UNITY IN DIVERSITY') }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <img :src="school.doc_cover" class="rounded img-fluid" @error="$event.target.src=PUBLIC_PATH+'img/cover/header_schooldirectory.png'"/>
          </div>
        </b-col>
        <b-col class="w-100" style="z-index:100">
          <b-button style="z-index:100" id="pick-cover" pill v-bind:class="[ownerSchool ? activeClassCover : defaultClassCover]"><b-icon icon="camera" class="mr-2" variant="light"></b-icon> {{ $t('Edit cover') }}</b-button>
          <avatar-cropper
            :upload-url="urlUpload"
            @uploading="handleUploading"
            @completed="handleCompleted"
            @error="handlerError"
            trigger="#pick-cover"
            :cropper-options="{ minContainerWidth: 1140, minContainerWidth: 320 }"
            :output-options="{ width:1140, height:320}"
            :output-quality="0.7"
            :upload-headers="uploadHeader"
            requestMethod="POST"
            :labels="{ submit: $t('Upload'), cancel: $t('Cancel')}"
            mimes="image/png, image/gif, image/jpeg"
            upload-form-name="doc_cover"
          />
        </b-col>
      </b-row>
      <b-row class="pt-4">
        <b-col lg="3" md="3" sm="12" class="container-avatar flex-justify-content-center">
          <div>
            <b-avatar v-if="school.doc_logo" variant="secondary" class="avatar-profile" :src="school.doc_logo"></b-avatar>
            <b-avatar v-else variant="secondary" class="avatar-profile" :src="PUBLIC_PATH+'img/icons/android-chrome-512x512.png'"></b-avatar>
          </div>
        </b-col>
        <b-icon style="height:40px; width:40px;margin-left: -100px; z-index: 100; margin-top:10px" id="pick-avatar" icon="camera" v-bind:class="[ownerSchool ? activeClassAvatar : defaultClassAvatar]" variant="light"></b-icon>
        <avatar-cropper
          :upload-url="urlUploadLogo"
          @uploading="handleUploading"
          @completed="handleCompleted"
          @error="handlerError"
          trigger="#pick-avatar"
          :output-options="{ width:512, height:512}"
          :output-quality="0.7"
          :upload-headers="uploadHeader"
          requestMethod="POST"
          :labels="{ submit: $t('Upload'), cancel: $t('Cancel')}"
          mimes="image/png, image/gif, image/jpeg"
          upload-form-name="doc_logo"
        />
        <b-col lg="6" md="9" sm="12" class="text-center">
          <h2 class="text-primary-dark content-header-title text-md-left text-lg-left d-sm-block">{{ school.school_name }}</h2>
          <b-row class="mt-3">
            <b-col lg="6" md="6" sm="12" class="text-center pr-0">
              <h5 class="text-gray-light content-title text-md-left text-lg-left font-weight-400">{{ $t('WYA School ID') }} : <span class="text-primary-dark">{{  (school.school_id || '') }}</span></h5>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="text-center pr-0">
              <h5 class="text-gray-light content-title text-md-left text-lg-left font-weight-400">{{ $t('Member Since') }} : <span class="text-primary-dark">{{ new Date(school.member_since) | dateFormat('DD MMMM YYYY') }}</span></h5>
            </b-col>
          </b-row>

           <b-row class="">
            <b-col lg="6" md="6" sm="12" class="text-center pr-0">
              <h5 class="text-gray-light content-title text-md-left text-lg-left font-weight-400">{{ $t('Valid Until') }} :
                <span class="text-primary-dark" v-if="school.expiration_date">{{ new Date(school.expiration_date) | dateFormat('DD MMMM YYYY')}}</span>
                <span class="text-primary-dark" v-else>{{ $t('Non-Expiring License') }}</span>
              </h5>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="text-center pr-0">
            </b-col>
          </b-row>

        </b-col>
        <b-col lg="3" md="12"  sm="12" class="">
          <b-col class="yoga-stat text-center d-flex d-lg-none mt-4 mb-4">
            <b-col cols="3" class="">
              <h4 class="stat-value text-primary-dark font-weight-600">{{ school.viewer | numeral('0,0') }}</h4>
              <span class="text-gray-light text-13">{{ $t('Viewers') }}</span>
            </b-col>
            <b-col cols="3">
              <h4 class="stat-value text-primary-dark font-weight-600">{{ school.training_event | numeral('0,0') }}</h4>
              <span class="text-gray-light text-13">{{ $t('Event') }}</span>
            </b-col>
            <b-col cols="3">
              <h4 class="stat-value text-primary-dark font-weight-600">{{ school.graduate | numeral('0,0') }}</h4>
              <span class="text-gray-light text-13">{{ $t('Graduates') }}</span>
            </b-col>
            <b-col cols="3">
              <b-button v-b-toggle.sidebar-1 pill variant="light" class="more-button btn-outline-default"><font-awesome-icon :icon="['fas', 'ellipsis-v']" /><span class="d-none">{{ $t('More')}}</span></b-button>
              <span class="d-block text-gray-light text-13" style="margin-top: 11px;">{{ $t('More') }}</span>
              <b-sidebar id="sidebar-1" backdrop>
                <div class="text-left py-2 bg-white">
                  <b-col cols="12">
                  <b-col cols="12" class=""><span class="text-primary-dark font-weight-bold content-title">{{ $t('Status') }}</span></b-col>
                  <div class="row mt-2 col-12">
                    <div class="d-inline-flex mb-2" v-for="school_type in school.school_type" :key="school_type.id">
                      <div class="d-flex align-items-center justify-content-center pl-0 pr-0">
                        <b-avatar variant="light" size="3.0rem" :src="PUBLIC_PATH+'img/certificate/' + school_type.school_type_code + '.png'"></b-avatar>
                      </div>
                      <div  class="d-flex pl-2 pr-0">
                        <span class="small text-primary-dark list-style-type-none">{{ school_type.school_type_name }}</span>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-col cols="12" class="mt-4"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Accredited Courses') }}</span></b-col>
                  <ul>
                    <li class="small text-primary-dark" style="text-transform: capitalize;" v-for="school_level in school.school_level" :key="school_level.id">
                      {{ school_level.school_level_name }}
                    </li>
                  </ul>
                </b-col>
                <b-col cols="12">
                    <b-col cols="12" class="mt-4"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Instructors') }}</span></b-col>
                </b-col>
                <b-col class="row mt-2">
                  <b-col cols="12">
                    <p class="d-none mb-0"><span class="text-13 text-danger-light d-none">ITALY</span></p>
                    <p class="mb-0"><span class="text-13 text-primary-dark font-weight-bold">{{ school.instructors_name }}</span></p>
                    <p class="d-none mb-0"><span class="text-12 text-primary-dark d-none"><span class="text-12 text-gray-light">Member Since :</span> 8 May 2019</span></p>
                  </b-col>
                </b-col>
                <b-col class="mt-4">
                    <b-col cols="12" class="mt-1"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Contact Us') }}</span></b-col>
                    <b-col cols="12">
                      <a v-if="school.website_url" :href="school.website_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-link border-0 mt-2"><font-awesome-icon :icon="['fas', 'link']" /></b-button></a>
                      <a v-if="school.facebook_url" :href="school.facebook_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-facebook border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'facebook-f']"/></b-button></a>
                      <a v-if="school.instagram_url" :href="school.instagram_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-instragram border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'instagram']"/></b-button></a>
                      <a v-if="school.twitter_url" :href="school.twitter_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-twitter border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'twitter']"/></b-button></a>
                    </b-col>
                    <b-col cols="12" class="pt-3">
                      <a :href="'tel:' + school.school_phone_number" class="small text-primary-dark d-block" v-if="school.school_phone_number"><b-icon icon="telephone-fill" /> {{ school.school_phone_number }}</a>
                      <a :href="'mailto:' + school.school_email" class="small text-primary-dark d-block" v-if="school.school_email"><b-icon icon="envelope-fill" /> {{ school.school_email }}</a>
                    </b-col>
                  </b-col>
                  <b-col class="mt-4">
                    <b-col cols="12" class="mt-1"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Location') }}</span></b-col>
                    <b-col v-if="(school.latitude && school.latitude) || (location.lat && location.lng )" cols="12 mt-2">
                      <GmapMap
                        :options="{
                          zoomControl: true,
                          mapTypeControl: false,
                          scaleControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: true,
                          disableDefaultUi: false
                        }"
                        :center="{ lat: parseFloat(school.latitude || location.lat), lng: parseFloat(school.longitude || location.lng) }"
                        :zoom="zoom"
                        style="width:100%;  height: 200px;"
                      >
                      <GmapMarker
                        :position="{ lat: parseFloat(school.latitude || location.lat), lng: parseFloat(school.longitude || location.lng) }"
                        :clickable="true"
                        @click="center={ lat: parseFloat(school.latitude || location.lat), lng: parseFloat(school.longitude || location.lng) }"
                      ></GmapMarker>
                      </GmapMap>
                    </b-col>
                    <b-col cols="12" class="mt-3">
                      <b-row>
                        <b-col class="" cols="2">
                          <span class="text-danger-light"><font-awesome-icon :icon="['fas', 'map-marker-alt']" /></span>
                        </b-col>
                        <b-col class="pl-0" cols="10">
                          <span class="text-13 text-primary-dark">{{ school.school_address || school.region_name }} </span>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-col>
                  <b-col class="col-12 mt-4">
                    <b-col cols="12" class="mt-1">
                      <span class="text-primary-dark font-weight-bold content-title">{{ $t('Image Gallery') }}</span><br>
                    </b-col>
                    <b-col cols="12 pt-2 pr-2 mb-4">
                      <b-row class="pr-3">
                        <b-col cols="12">
                          <CoolLightBox
                            :items="schoolGallerySlide"
                            :index="indexImg1"
                            @close="indexImg1 = null">
                          </CoolLightBox>
                          <div class="images-wrapper row w-100">
                            <div class="col-4" v-for="(image, imageIndex) in schoolGallerySlide" :key="imageIndex">
                              <div
                                class="image"
                                @click="indexImg1 = imageIndex"
                                :style="{ backgroundImage: 'url(' + image.thumb + ')'}"
                              >
                              <b-dropdown style="z-index:100" v-if="userProfile && userProfile.id == school.user" size="sm" variant="light" right no-caret class="btn-edit-gallery float-right p-0">
                                <template #button-content>
                                  <b-icon icon="three-dots-vertical" font-scale="1" class="p-0"></b-icon>
                                </template>
                                <b-dropdown-item style="z-index:100" class="text-14" @click="setDataEditImg(image), $bvModal.show('modal-edit-img-gallery')"><b-icon icon="pencil" class="mt-n1 text-13"></b-icon> {{ $t('Edit') }}</b-dropdown-item>
                                <b-dropdown-item style="z-index:100" class="text-14" @click="onDeleteImgGallery(image), $bvModal.show('modal-delete-img-gallery')"><b-icon icon="trash" class="mt-n1 text-13"></b-icon> {{ $t('Delete') }}</b-dropdown-item>
                              </b-dropdown>
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <div v-if="schoolGallerySlide.length == 0 && !userProfile" class="text-left col">
                          <span class="text-14">{{ $t('No results') }}</span>
                        </div>
                        <b-col v-if="userProfile && userProfile.id == school.user && schoolGallerySlide.length < 15" cols="4" class="pr-0">
                          <div v-b-modal.modal-add-img-gallery class="border border-1 rounded w-100 d-flex justify-content-center align-items-center add-img-gallery">
                            <b-icon scale="2" :title="$t('Add image gallery')" icon="plus"/>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-col>
                </div>
              </b-sidebar>
            </b-col>
          </b-col>
          <div v-if="!ownerSchool" class="flex-justify-content-space-around flex-wrap-wrap">
              <div>
                <b-button id="popover-share-button-sync" pill class="mt-2 px-4 px-md-5 px-lg-5 px-xl-3 py-2 font-weight-bold btn-primary-dark d-none"><b-icon icon="reply-fill" class="mr-2"></b-icon>{{ $t('Share') }}</b-button>
              </div>
              <div class="d-none">
                <b-button pill class="mt-2 px-4 px-md-5 px-lg-5 px-xl-3 py-2 ml-0 ml-xl-3 font-weight-bold btn-danger-light"><b-icon icon="heart-fill" class="mr-3"></b-icon>{{ $t('Like') }}</b-button>
              </div>
              <b-popover boundary-padding="10" placement="auto" target="popover-share-button-sync" triggers="click blur">
                <div>
                  <p>SHARE VIA</p>
                  <hr/>
                  <social-sharing :id="viewSchoolId"/>
                </div>
              </b-popover>
          </div>
        </b-col>
      </b-row>
      <b-row class="pt-5 pt-lg-3 pt-xl-4 profile-main-wrap">
        <b-col lg="3" class="d-none d-lg-block">
          <b-row class="yoga-stat text-center d-none d-lg-flex">
            <b-col cols="4" class="pl-1">
              <h4 class="text-primary-dark font-weight-600">{{ school.viewer | numeral('0,0') }}</h4>
              <span class="text-gray-light text-13">{{ $t('Viewers') }}</span>
            </b-col>
            <b-col cols="4">
              <h4 class="text-primary-dark font-weight-600">{{ school.training_event | numeral('0,0') }}</h4>
              <span class="text-gray-light text-13">{{ $t('Event') }}</span>
            </b-col>
            <b-col cols="4" class="px-0">
              <h4 class="text-primary-dark font-weight-600">{{ school.graduate | numeral('0,0') }}</h4>
              <span class="text-gray-light text-13">{{ $t('Graduates') }}</span>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-button v-if="ownerSchool" v-b-modal.modal-update-school-profile @click="getFormdata" pill class="bth btn-danger-light font-weight-bold text-14 px-3"><b-icon icon="pencil" class="mr-2" variant="light"></b-icon>{{ $t('Edit Info') }}</b-button>
          </b-row>
          <b-row class="col-12 mt-4">
            <b-col cols="12" class="pl-0"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Status') }}</span></b-col>
            <div class="row mt-3 col-12">
              <div class="d-inline-flex mb-3" v-for="school_type in school.school_type" :key="school_type.id">
                <div class="d-flex align-items-center justify-content-center pl-0 pr-0">
                  <b-avatar variant="light" size="3.0rem" :src="PUBLIC_PATH+'img/certificate/' + school_type.school_type_code + '.png'"></b-avatar>
                </div>
                <div  class="d-flex pl-2 pr-0">
                  <span class="small text-primary-dark list-style-type-none">{{ school_type.school_type_name }}</span>
                </div>
              </div>
            </div>
            <b-col cols="12" class="pl-0 mt-3"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Accredited Courses') }}</span></b-col>
            <ul class="pl-3 mt-3">
              <li class="small text-primary-dark mb-2" style="text-transform: capitalize;" v-for="school_level in school.school_level" :key="school_level.id">
                {{ school_level.school_level_name }}
              </li>
            </ul>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-2"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Instructors') }}</span></b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <p class="mb-0 d-none"><span class="text-13 text-danger-light d-none">ITALY</span></p>
              <p class="mb-0"><span class="text-13 text-primary-dark font-weight-bold">{{ school.instructors_name }}</span></p>
              <p class="mb-0 d-none"><span class="text-12 text-primary-dark d-none"><span class="text-12 text-gray-light">Member Since :</span> 8 May 2019</span></p>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="12" class="mt-1"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Contact Us') }}</span></b-col>
            <b-col cols="12 mt-2">
              <a v-if="school.website_url" :href="school.website_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-link border-0 mt-2"><font-awesome-icon :icon="['fas', 'link']" /></b-button></a>
              <a v-if="school.facebook_url" :href="school.facebook_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-facebook border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'facebook-f']"/></b-button></a>
              <a v-if="school.instagram_url" :href="school.instagram_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-instragram border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'instagram']"/></b-button></a>
              <a v-if="school.twitter_url" :href="school.twitter_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-twitter border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'twitter']"/></b-button></a>
            </b-col>
            <b-col cols="12" class="mt-3">
              <a :href="'tel:' + school.school_phone_number" class="small text-primary-dark d-block mb-3" v-if="school.school_phone_number"><b-icon icon="telephone-fill" /> {{ school.school_phone_number }}</a>
              <a :href="'mailto:' + school.school_email" class="small text-primary-dark d-block" v-if="school.school_email"><b-icon icon="envelope-fill" /> {{ school.school_email }}</a>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="12" class="mt-1"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Location') }}</span></b-col>
            <b-col v-if="(school.latitude && school.latitude) || (location.lat && location.lng )" cols="12 mt-2">
              <GmapMap
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUi: false
                }"
                :center="{ lat: parseFloat(school.latitude || location.lat), lng: parseFloat(school.longitude || location.lng) }"
                :zoom="zoom"
                style="width:100%;  height: 200px;"
              >
              <GmapMarker
                :position="{ lat: parseFloat(school.latitude || location.lat), lng: parseFloat(school.longitude || location.lng) }"
                :clickable="true"
                @click="center={ lat: parseFloat(school.latitude || location.lat), lng: parseFloat(school.longitude || location.lng) }"
              ></GmapMarker>
              </GmapMap>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-row>
                <b-col class="" cols="2">
                  <span class="text-danger-light"><font-awesome-icon :icon="['fas', 'map-marker-alt']" /></span>
                </b-col>
                <b-col class="pl-0" cols="10">
                  <span class="text-13 text-primary-dark">{{ school.school_address || school.region_name }} </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="12" class="mt-1">
              <span class="text-primary-dark font-weight-bold content-title">{{ $t('Image Gallery') }}</span><br>
            </b-col>
            <b-col cols="12 pt-2 pr-2 mb-4">
              <b-row class="pr-3">
                <b-col cols="12">
                  <CoolLightBox
                    ref="light_box"
                    :items="schoolGallerySlide"
                    :index="index"
                    :disabled="true"
                    @on-open="closeModalPreviewImg"
                    @close="index = null">
                  </CoolLightBox>
                  <div class="images-wrapper row w-100">
                    <div style="z-index:90" class="col-4" v-for="(image, imageIndex) in schoolGallerySlide" :key="imageIndex">
                      <div
                        class="image"
                        @click="index = imageIndex"
                        :style="{ backgroundImage: 'url(' + image.thumb + ')'}"
                      >
                      <b-dropdown style="z-index:100" v-if="userProfile && userProfile.id == school.user" size="sm" variant="light" right no-caret class="btn-edit-gallery float-right p-0">
                        <template #button-content>
                          <b-icon icon="three-dots-vertical" font-scale="1" class="p-0"></b-icon>
                        </template>
                        <b-dropdown-item style="z-index:100" class="text-14" @click="setDataEditImg(image), $bvModal.show('modal-edit-img-gallery')"><b-icon icon="pencil" class="mt-n1 text-13"></b-icon> {{ $t('Edit') }}</b-dropdown-item>
                        <b-dropdown-item style="z-index:100" class="text-14" @click="onDeleteImgGallery(image), $bvModal.show('modal-delete-img-gallery')"><b-icon icon="trash" class="mt-n1 text-13"></b-icon> {{ $t('Delete') }}</b-dropdown-item>
                      </b-dropdown>
                      </div>
                    </div>
                  </div>
                </b-col>
                <div v-if="schoolGallerySlide.length == 0 && !userProfile" class="text-center col">
                  <span class="text-14">{{ $t('No results') }}</span>
                </div>
                <b-col v-if="userProfile && userProfile.id == school.user && schoolGallerySlide.length < 15" cols="4" class="pr-0">
                  <div v-b-modal.modal-add-img-gallery class="border border-1 rounded w-100 d-flex justify-content-center align-items-center add-img-gallery">
                    <b-icon scale="2" :title="$t('Add image gallery')" icon="plus"/>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="9" class="">
          <b-tabs
            content-class="pt-4"
            active-nav-item-class="font-weight-bold text-primary-dark border-2"
            nav-class="text-gray-light school-profile"
            lazy
            v-model="tabIndex"
            @activate-tab="eventActiveTab"
            >
            <b-tab>
              <template #title>
                <b-icon icon="person" :title="$t('Introduction')" class="d-md-none d-lg-none"/>
                <span class="d-none d-md-block d-lg-block">{{ $t('Introduction') }}</span>
              </template>
              <b-row>
                <b-col cols="12" lg="8" md="12">
                  <b-col cols="12" class="py-2 box-graduate">
                    <span class="text-primary-dark font-weight-bold content-title">{{ $t('Graduates') }}</span><br>
                    <div class="mt-2 mb-2">
                       <b-skeleton-wrapper>
                        <template>
                          <div class="d-flex" v-if="isLoadingGraduate">
                          <b-skeleton type="avatar" class="mr-2"></b-skeleton>
                          <b-skeleton type="avatar" class="mr-2"></b-skeleton>
                          <b-skeleton type="avatar" class="mr-2"></b-skeleton>
                          <b-skeleton type="avatar" class="mr-2"></b-skeleton>
                          <b-skeleton type="avatar" class="mr-2"></b-skeleton>
                          <b-skeleton type="avatar" class="mr-2"></b-skeleton>
                          </div>
                        </template>
                        <div v-if="!isLoadingGraduate">
                          <div v-if="schoolGraduatedIntro.length > 0">
                            <b-avatar v-for="graduatedIntro in schoolGraduatedIntro" :key="graduatedIntro.id" variant="light" size="2.9rem" class="mr-3" :src="graduatedIntro.avatar" :title="graduatedIntro.graduate_no +' '+ graduatedIntro.first_name + ' ' + (graduatedIntro.last_name || '')" />
                            <a href="#"  @click="tabIndex=2" class="text-danger-light text-13">{{ $t('View more') }}</a>
                          </div>
                          <div v-else>
                            <span class="text-center d-block text-14">{{ $t('No results') }}</span>
                          </div>
                        </div>
                       </b-skeleton-wrapper>
                    </div>
                  </b-col>
                  <b-col cols="12" class="pt-5 d-block px-0">
                    <span class="text-primary-dark font-weight-bold content-title d-block">{{ $t('Training and Event') }}</span><br/>
                    <div class="training-list">
                      <vue-horizontal-list :items="eventsIntro" :options="options">
                        <template v-slot:default="{item}">
                          <router-link :to="{ name: 'eventDetail', params: { eventId: item.id }}">
                          <div class="item item-popular rounded-lg">
                            <div class="img-wrapper rounded">
                              <b-img class="border-0" :src="item.image_small"></b-img>
                              <div class="p-3 overlay-text text-white d-block justify-content-bottom">
                                <span class="d-block font-weight-bold">{{  new Date(item.start_date) |  dateFormat('MMM DD', dateFormatConfig) }} - {{  new Date(item.end_date) | dateFormat('MMM DD', dateFormatConfig) }}</span>
                                <small class="d-block">{{ new Date('1999-01-01T'+ item.start_time) | dateFormat('HH:mm A') }}</small>
                              </div>
                            </div>
                          </div>
                          </router-link>
                        </template>
                      </vue-horizontal-list>
                      <div v-if="eventsIntro.length == 0" class="justify-content-center d-flex">
                        <span class="text-14 mt-2">{{ $t('No results') }}</span>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" class="pt-5 pb-4 d-block px-0 text-primary-dark">
                    <span class="font-weight-bold content-title">{{ $t('Introduction') }}</span><br>
                    <p class="mt-1 d-block text-gray-light" style="white-space: pre-line">{{ school.description }}</p>
                    <span class="mt-3 font-weight-bold">{{ $t('Style of Yoga') }}</span>
                    <span class="mt-1 d-block text-gray-light">
                      <span v-for="(style_of_yoga, index ) in school.school_style_of_yoga" :key="style_of_yoga.id">
                        <span v-if="index == school.school_style_of_yoga.length - 1">{{ style_of_yoga.school_style_of_yoga_name }}</span>
                        <span v-else>{{ style_of_yoga.school_style_of_yoga_name }}, </span>
                      </span>
                    </span>
                    <span class="mt-3 d-block font-weight-bold">{{ $t('Primary Contact') }}</span>
                    <span class="mt-1 d-block text-gray-light">{{ school.owner_contact_name }}</span>
                    <span class="mt-1 d-block text-gray-light">{{ school.owner_email }}</span>
                    <span class="mt-1 d-block text-gray-light">{{ school.owner_phone_number }}</span>
                  </b-col>
                </b-col>
                <b-col cols="4 d-none d-lg-block">
                  <span class="font-weight-bold text-primary-dark content-title">{{ $t('Rating and Comments') }}</span>
                  <div class="d-flex justify-content-between pt-2">
                    <div>
                      <h2 class="font-weight-bold text-primary-dark d-inline mr-2">{{ school.rating | numeral('0.0') }}</h2><span class="d-inline text-gray-light">Out of 5</span>
                    </div>
                    <div class="text-13 justify-content-center text-right">
                      <b-form-rating v-model="school.rating" readonly color="#F04B4C" size="sm" class="p-0 ml-n1 border-0" style="font-size:0.6rem"></b-form-rating>
                      <small class="d-block text-gray-light">{{ school.rating | numeral('0.0') }} {{ $t('ratings') }}</small>
                    </div>
                  </div>
                  <b-row v-for="comment in comments" :key="comment.id" class="mt-4">
                      <b-col cols="3" class="d-flex align-items-center justify-content-center pl-1 pr-1">
                        <b-avatar variant="light" size="2.9rem" :src="comment.user_avatar"></b-avatar>
                      </b-col>
                      <b-col cols="9" class="pl-0">
                        <span class="text-13 text-primary-dark font-weight-bold text-capitalize">{{ comment.user_first_name + ' ' + comment.user_last_name }}</span><br>
                        <div class="row">
                          <div class="col-4">
                            <b-form-rating v-model="comment.rating" readonly color="#F04B4C" size="sm" class="p-0 ml-n1 border-0" style="font-size:0.6rem"></b-form-rating>
                          </div>
                          <div class="col-8">
                            <small class="text-12">{{ new Date(comment.created_at) | dateFormat('DD MMM YYYY, HH:mm', dateFormatConfig) }}</small>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12" class="mx-2">
                        <span class="text-13">{{ comment.comment }}</span>
                      </b-col>
                    </b-row>
                  <b-button v-if="comments.length > 0" class="mt-4 text-danger-light px-3" variant="outline-light" @click="tabIndex=3">{{ $t('View more') }}</b-button>
                  <div v-if="comments.length == 0" class="text-center">
                    <span class="text-13">{{ $t('No results') }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <template #title>
                <b-icon icon="calendar2-date" class="d-md-none d-lg-none"/>
                <span class="d-none d-md-block d-lg-block">{{ $t('Training & Event') }}</span>
              </template>
              <div>
                <SchoolEvent :resultItems="events" :pastResultItems="pastEvents" :school="school" @onDeleteEvent="onDeleteEvent" @onChangeMonthEvent="onChangeMonthEvent" :isLoading="isLoadingEvent" />
              </div>
            </b-tab>
            <b-tab :title="$t('Graduates')">
              <template #title>
                <font-awesome-icon :icon="['fas', 'graduation-cap']" class="d-md-none d-lg-none"/>
                <span class="d-none d-md-block d-lg-block">{{ $t('Graduates') }}</span>
              </template>

              <div>
                <SchoolGraduated :resultItems="schoolGraduateds" :school="school" @addGraduate="addGraduate" @editGraduate="editGraduate" @onDeleteGraduate="onDeleteGraduate"  :isLoadingForm="isLoadingFormAddGraduate" :closeModalForm="closeModalFormAddGraduate" @onSearchGraduate="onSearchGraduate"/>
                <b-row class="px-3 mt-4 d-flex justify-content-end" v-if="schoolGraduatedObj.count > itemPerpageGraduated">
                  <div class="d-flex justify-content-right">
                    <b-pagination
                      v-model="currentPageGraduated"
                      :total-rows="schoolGraduatedObj.count"
                      :per-page="itemPerpageGraduated"
                      :first-text="$t('First')"
                      :prev-text="$t('Prev')"
                      :next-text="$t('Next')"
                      :last-text="$t('Last')"
                      page-class="font-weight-bold"
                    ></b-pagination>
                  </div>
                </b-row>
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <b-icon icon="chat-left-text" class="d-md-none d-lg-none"/>
                <span class="d-none d-md-block d-lg-block">{{ $t('Rating and Comments') }}</span>
              </template>
              <div>
                <SchoolRatingAndComment :school="school" :resultItems="comments" :ratingSummary="ratingCommentSummary" :nextPage="commentNextPage" :myComment="myComment" @submitComment="addComment" :isLoading="isLoadingComment"  @viweMore="viewMoreComment" />
              </div>
              <span class="text-center d-none">{{ $t('No results') }}</span>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      </div>
    </b-container>
    <b-modal id="modal-update-school-profile" hide-footer centered size="lg" :title="$t('Edit School Info')">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <validation-provider
            :name="$t('School name')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group>
            <label for="school_name" class="text-primary-dark font-weight-600">{{ $t('School name') }} <span class="text-danger-light">*</span></label>
            <b-form-input
              id="school_name"
              type="text"
              :readonly="true"
              v-model="form.school_name"
              :placeholder="$t('Enter school name')"
            >
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <span class="text-primary-dark font-weight-600 text-18">{{ $t('Status / Certified Courses') }}</span>
          <b-form-group class="mt-2">
            <div>
              <label for="level_of_registration" class="text-primary-dark font-weight-600">{{ $t('Level of Registration') }} <span class="text-danger-light">*</span></label>
              <b-form-checkbox v-model="levelTypechecked" name="check-button" disabled switch class="ml-2 text-right d-inline">
                <b>{{ levelTypechecked ? $t('Lifetime') : $t('Annual') }}</b>
              </b-form-checkbox>
            </div>
            <validation-provider
              :name="$t('Level of Registration')"
              rules="required"
              autocomplete="off"
              v-slot="{errors}"
            >
            <multiselect
                 v-model="form.school_level"
                 :options="school_level_options"
                 :multiple="true"
                 :disabled="true"
                 :placeholder="$t('Level of Registration')"
                 :selectedLabel="$t('Selected')"
                 :deselectLabel="$t('Press enter to remove')"
                 :selectLabel="$t('Press enter to select')"
                 label="school_level_name"
                 track-by="school_level"
                 ></multiselect>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="additional_level_of_registration" class="text-primary-dark font-weight-600">{{ $t('Additional Level of Registration (Optional)') }}</label>
            <multiselect
                 v-model="form.school_level_additional"
                 :options="school_level_additional_options"
                 :multiple="true"
                 :disabled="true"
                 :placeholder="$t('Additional Level of Registration (Optional)')"
                 :selectedLabel="$t('Selected')"
                 :deselectLabel="$t('Press enter to remove')"
                 :selectLabel="$t('Press enter to select')"
                 label="school_level_additional_name"
                 track-by="school_level_additional"
                 ></multiselect>
          </b-form-group>
          <validation-provider
            :name="$t('Introduction')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group>
            <label for="school_description" class="text-primary-dark font-weight-600">{{ $t('Introduction') }} <span class="text-danger-light">*</span></label>
            <b-form-textarea
              id="school_description"
              type="text"
              v-model="form.description"
              :placeholder="$t('Enter Introduction')"
              rows="4"
              max-rows="10"
              max-length="2000"
            >
            </b-form-textarea>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <span class="text-primary-dark font-weight-600 text-18"> {{ $t('Instructors') }}</span>
          <b-form-group class="mt-2">
            <label for="instructors_name" class="text-primary-dark font-weight-600">{{ $t('Instructors name') }} <span class="text-danger-light">*</span></label>
            <validation-provider
              :name="$t('Instructors name')"
              rules="required"
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-input
              id="instructors_name"
              v-model="form.instructors_name"
              type="text"
              :placeholder="$t('Enter instructors name')"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :description="$t('* For those who wish to open yoga training programs, please specify yoga educational background for Instructors.')"
          >
            <label for="instructors_certification" class="text-primary-dark font-weight-600">{{ $t('Instructors certification details') }} <span class="text-danger-light">*</span></label>
            <validation-provider
              :name="$t('Instructors certification details')"
              rules="required"
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-select
              id="instructors_certification"
              v-model="form.school_main_teacher_certification"
              :options="schoolFormData.school_main_teacher_certifications"
              value-field="id"
              text-field="name"
            >
            <template #first>
              <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
            </template>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="instructor_expert_year" class="text-primary-dark font-weight-600">{{ $t('How many years has instructors been teaching yoga?') }} <span class="text-danger-light">*</span></label>
            <validation-provider
              :name="$t('How many years has instructors been teaching yoga?')"
              rules="required"
              autocomplete="off"
              v-slot="{errors}"
            >
              <b-form-select
                id="instructor_expert_year"
                v-model="form.school_main_teacher_teaching"
                :options="schoolFormData.school_main_teacher_teaching"
                value-field="id"
                text-field="name"
              >
              <template #first>
                <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
              </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <span class="text-primary-dark font-weight-600 text-18">{{ $t('Contact Us') }}</span>
            <b-row class="mt-2">
              <!-- <b-row> -->
                <b-col sm="12" lg="6">
                  <validation-provider
                    :name="$t('Email')"
                    rules="required|email"
                    autocomplete="off"
                    v-slot="{errors}"
                  >
                  <b-form-group>
                    <label for="email" class="text-primary-dark font-weight-600">{{ $t('Email') }} <span class="text-danger-light">*</span></label>
                    <b-form-input
                      id="email"
                      v-model="form.school_email"
                      :placeholder="$t('Enter Email')"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col sm="12" lg="6">
                  <validation-provider
                    :name="$t('Phone number')"
                    rules="required"
                    autocomplete="off"
                    v-slot="{errors}"
                  >
                    <b-form-group>
                      <label for="phone" class="text-primary-dark font-weight-600">{{ $t('Phone number') }} <span class="text-danger-light">*</span></label>
                      <b-form-input
                        id="phone"
                        rules="required"
                        v-model="form.school_phone_number"
                        :placeholder="$t('Enter Phone number')"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              <!-- </b-row> -->
              <b-form-group class="col-lg-6">
                <label for="website" class="text-primary-dark font-weight-600">{{ $t('Website') }}</label>
                <b-form-input
                  id="website"
                  v-model="form.website_url"
                  :placeholder="$t('Paste your link here')"
                ></b-form-input>
              </b-form-group>
              <b-form-group class="col-lg-6">
                <label for="facebook_URL" class="text-primary-dark font-weight-600">{{ $t('Facebook URL') }}</label>
                <b-form-input
                  id="facebook_URL"
                  v-model="form.facebook_url"
                  :placeholder="$t('Paste your link here')"
                ></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-lg-6">
                <label for="twitter_URL" class="text-primary-dark font-weight-600">{{ $t('Twitter URL') }}</label>
                <b-form-input
                  id="twitter_URL"
                  v-model="form.twitter_url"
                  :placeholder="$t('Paste your link here')"
                ></b-form-input>
              </b-form-group>
              <b-form-group class="col-lg-6">
                <label for="instagram_URL" class="text-primary-dark font-weight-600">{{ $t('Instagram URL') }}</label>
                <b-form-input
                  id="instagram_URL"
                  v-model="form.instagram_url"
                  :placeholder="$t('Paste your link here')"
                ></b-form-input>
              </b-form-group>
          </b-row>
          <span class="text-primary-dark font-weight-600 text-18">{{ $t('Location') }}</span>
          <validation-provider
            :name="$t('Address')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group class="mt-2">
            <label for="address" class="text-primary-dark font-weight-600">{{ $t('Address') }} <span class="text-danger-light">*</span></label>
            <b-form-input
              id="address"
              v-model="form.school_address"
              :placeholder="$t('Enter Address')"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('Location')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group>
            <label for="location" class="text-primary-dark font-weight-600">{{ $t('Location') }} <span class="text-danger-light">*</span></label>
            <v-select id="location" class="form-v-selects" :placeholder="$t('Province or States')" label="text" :filterable="false" :options="regions" v-model="selectedRegion" @search="searchRegion">
              <template #search="{attributes, events}">
                <input
                  class="vs__search 11"
                  v-bind="attributes"
                  v-on="events"
                  @click="setReadOnly"
                />
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{ $t('No results found for') }} <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>{{ $t('Typing to search for a Province or States') }}</em>
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.text }}
                  </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.text }}
                </div>
              </template>
            </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <div class="w-100 justify-content-center d-flex">
            <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-update-school-profile')">
              {{ $t('Cancel') }}
            </b-button>
            <b-button pill type="submit" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark">
              <b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>
              {{ $t('Save') }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <b-modal size="lg" ref="modal-add-img-gallery" id="modal-add-img-gallery" hide-footer body-class="pt-0"  :title="$t('Add Image Gallery')">
      <ValidationObserver ref="formGallery" v-slot="{ handleSubmit }">
        <b-form ref="formImageGallery" @submit.stop.prevent="handleSubmit(onSubmitImageGallery)">
          <b-img v-bind="mainPropImage" v-if="imageGallery"  :src="imageGallery" fluid rounded alt="Image" style="max-height:400px"></b-img>
          <validation-provider
            :name="$t('Image')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group class="d-inline col-12 pl-0 font-weight-bold text-primary-dark" for="image-gallery" :label="$t('Image')+'*'">
            <b-form-file
              v-model="formImageGallery.image"
              type="file"
              id="image-gallery"
              :placeholder="$t('Choose a file or drop it here')"
              :drop-placeholder="$t('Drop file here')"
              accept="image/*"
              @change="onImageGalleryChange"
            ></b-form-file>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('Image description')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group class="d-inline col-12 pl-0 font-weight-bold text-primary-dark" for="img-gallery-description" :label="$t('Image description')+'*'">
            <b-form-input id="img-gallery-description" type="text" v-model="formImageGallery.title" :placeholder="$t('Image description')"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <div class="w-100 justify-content-center d-flex">
            <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-add-img-gallery'), resetFormImgGallery()">
              {{ $t('Cancel') }}
            </b-button>
            <b-button pill type="submit" :disabled="isLoadingImageGallery" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark">
              <b-spinner small class="mr-3" v-if="isLoadingImageGallery"  label="Loading..."/>
              {{ $t('Save') }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <b-modal ref="modal-edit-img-gallery" id="modal-edit-img-gallery" @hidden="resetDataEditImg()" hide-footer centered :title="$t('Edit Image')">
       <ValidationObserver ref="formGallery" v-slot="{ handleSubmit }">
        <b-form ref="formImageGallery" @submit.stop.prevent="handleSubmit(onSubmitEditImageGallery)">
          <b-img v-bind="mainPropImage" v-if="editImageGallery"  :src="editImageGallery" fluid rounded alt="Image" style="max-width:466px"></b-img>
          <validation-provider
            :name="$t('Image')"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group class="d-inline col-12 pl-0 font-weight-bold text-primary-dark" for="image-gallery" :label="$t('Image')">
            <b-form-file
              v-model="formEditImageGallery.image"
              type="file"
              id="image-gallery"
              :placeholder="$t('Choose a file or drop it here')"
              :drop-placeholder="$t('Drop file here')"
              accept="image/*"
              @change="onEditImageGalleryChange"
            ></b-form-file>
            <small class="text-danger px-1">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('Image description')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group class="d-inline col-12 pl-0 font-weight-bold text-primary-dark" for="img-gallery-description" :label="$t('Image description')+'*'">
            <b-form-input id="img-gallery-description" type="text" v-model="formEditImageGallery.title" :placeholder="$t('Image description')"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <div class="w-100 justify-content-center d-flex">
            <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-edit-img-gallery'), resetFormImgGallery()">
              {{ $t('Cancel') }}
            </b-button>
            <b-button pill type="submit" :disabled="isLoadingEditImageGallery" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark">
              <b-spinner small class="mr-3" v-if="isLoadingEditImageGallery"  label="Loading..."/>
              {{ $t('Save') }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <b-modal ref="modal-delete-img-gallery" id="modal-delete-img-gallery" @hidden="setDataDeleteImg()" hide-footer centered :title="$t('Delete Image')">
      <div class="text-center">
        <b-img rounded :src="deleteImgSrc" /><br>
        <p class="mt-2">{{ $t('Do you want to delete image {name}', { name: deleteImgTitle }) }}</p>
      </div>
      <div class="w-100 justify-content-center d-flex">
        <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-delete-img-gallery')">
          {{ $t('Cancel') }}
        </b-button>
        <b-button pill type="submit" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark" @click="onSubmitDeleteImg">
          <b-spinner small class="mr-3" v-if="isLoadingDeleteImg"  label="Loading..."/>
          {{ $t('Delete') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueHorizontalList from 'vue-horizontal-list'
import SchoolEvent from '../../components/school/SchoolEvent.vue'
import SchoolGraduated from '../../components/school/SchoolGraduate.vue'
import SchoolRatingAndComment from '../../components/school/SchoolRatingAndComment.vue'
import SocialSharing from '../../components/SocialSharing.vue'
import Api from '../../api/api'
import 'vue-select/dist/vue-select.css'
import AvatarCropper from 'vue-avatar-cropper'
import Multiselect from 'vue-multiselect'

const axios = require('axios')

export default {
  name: 'SchoolProfile',
  title: ' | School profile',
  metaInfo  () {
    return {
      meta: [
        // Twitter Card
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.school.school_name },
        { name: 'twitter:description', content: this.school.description },
        // image must be an absolute path
        { name: 'twitter:image', content: this.school.doc_logo },
        // Facebook OpenGraph
        { property: 'og:title', content: this.school.school_name },
        { property: 'og:image', content: this.school.doc_logo },
        { property: 'og:description', content: this.school.description }
      ]
    }
  },
  components: {
    VueHorizontalList,
    SchoolEvent,
    SchoolGraduated,
    SchoolRatingAndComment,
    AvatarCropper,
    SocialSharing,
    Multiselect
  },
  data () {
    return {
      zoom: 15,
      tabIndex: 0,
      loading: true,
      activeClassCover: 'bth d-block float-right mr-lg-3 mt-n5 btn-danger-light font-weight-bold text-12',
      defaultClassCover: 'bth d-none float-right mr-lg-3 mt-n5 btn-danger-light font-weight-bold text-12',
      activeClassAvatar: 'rounded-circle i-con-avatar btn-danger-light p-2',
      defaultClassAvatar: 'rounded-circle i-con-avatar btn-danger-light p-2 d-none',
      ownerSchool: false,
      ownerSchoolId: null,
      viewSchoolId: null,
      school: {},
      levelTypechecked: true,
      form: {
        id: null,
        school_name: '',
        description: '',
        school_level: [],
        school_level_additional: [],
        instructors_name: '',
        school_main_teacher_certification: '',
        school_main_teacher_teaching: '',
        school_email: '',
        school_phone_number: '',
        website_url: '',
        facebook_url: '',
        twitter_url: '',
        instagram_url: '',
        school_address: '',
        region: ''
      },
      selectedRegion: null,
      regions: [],
      schoolFormData: {
        school_levels: [],
        school_level_additionals: []
      },
      comments: [],
      commentPage: 1,
      myComment: {
        rating: 0,
        comment: null,
        school: null,
        user: null
      },
      commentLimitPerPage: 5,
      commentNextPage: null,
      ratingCommentSummary: null,
      schoolGalleries: [],
      schoolGallerySlide: [],
      schoolGraduateds: [],
      schoolGraduatedObj: {},
      schoolGraduatedIntro: [],
      isLoadingFormAddGraduate: false,
      closeModalFormAddGraduate: false,
      currentPageGraduated: 1,
      itemPerpageGraduated: 19,
      searchTextGraduated: null,
      orderingGraduated: null,
      events: [],
      eventsIntro: [],
      pastEvents: [],
      isLoadingGraduate: false,
      isLoadingComment: false,
      isLoadingEvent: false,
      isLoading: false,
      urlUpload: null,
      urlUploadLogo: null,
      uploadHeader: {},
      options: {
        responsive: [
          // responsive breakpoints to calculate how many items to show in the list at each width interval
          // it will always fall back to these:
          { end: 576, size: 3 },
          { start: 576, end: 768, size: 2 },
          { start: 768, end: 992, size: 3 },
          { start: 992, end: 1200, size: 4 },
          { start: 1200, size: 5 }
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,
          // Because: #app {padding: 80px 24px;}
          padding: 10
        }
      },
      filter: {},
      mainPropImage: {
        blankColor: '#bbb',
        width: 960,
        height: 540,
        class: 'my-2'
      },
      imageGallery: null,
      editImageGallery: null,
      formImageGallery: {
        school: null,
        image: null,
        title: null,
        is_active: true
      },
      formEditImageGallery: {
        id: null,
        school: null,
        image: null,
        title: null,
        is_active: true
      },
      isLoadingImageGallery: false,
      isLoadingEditImageGallery: false,
      school_level_options: [],
      school_level_additional_options: [],
      location: {},
      index: null,
      indexImg1: null,
      isLoadingDeleteImg: false,
      deleteImgTitle: '',
      deleteImgId: null,
      deleteImgSrc: null
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    },
    schoolId () {
      return this.$store.getters.schoolId
    },
    currentLanguage () {
      return this.$store.getters.currentLanguage
    }
  },
  watch: {
    currentLanguage (newValue, oldValue) {
      this.getSchoolDetail()
    },
    currentPageGraduated: function () {
      this.graduatedList(this.searchTextGraduated, this.orderingGraduated)
    }
  },
  async mounted () {
    this.uploadHeader = await { Authorization: 'Bearer ' + localStorage.getItem('accessToken') }
    this.urlUpload = await process.env.VUE_APP_API_ENDPOINT + '/api/school/school/' + this.schoolId + '/doc_cover'
    this.urlUploadLogo = process.env.VUE_APP_API_ENDPOINT + '/api/school/school/' + this.schoolId + '/doc_logo'
    window.scrollTo(0, 0)
    this.viewSchoolId = this.$route.params.schoolId ? this.$route.params.schoolId : null
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    const schoolId = localStorage.getItem('schoolId')
    if (schoolId === null && this.viewSchoolId === null && this.userProfile) {
      this.$router.push({ name: 'schoolRegisterInformation' })
    } else {
      if (schoolId && this.viewSchoolId === null) {
        this.ownerSchoolId = schoolId
      } else {
        this.ownerSchoolId = this.viewSchoolId
      }
    }
    this.getSchoolDetail()
    this.schoolComment()
    this.mySchoolComment()
    this.schoolGallery()
    this.graduatedList()
    this.schoolRatingCommentSummary()
    this.uploadHeader = { Authorization: 'Bearer ' + localStorage.getItem('accessToken') }
    var filterDate = new Date()
    var firstDay = filterDate
    var lastDay = new Date(filterDate.getFullYear(), filterDate.getMonth() + 1, 0)
    this.filter = {
      start_date__gte: this.$options.filters.dateFormat(firstDay, 'YYYY-MM-DD'),
      end_date__lte: this.$options.filters.dateFormat(lastDay, 'YYYY-MM-DD')
    }
    this.eventList(null, 'intro')
    // pass event
    const filterPassEvent = {
      start_date__lte: this.$options.filters.dateFormat(new Date(filterDate.getFullYear(), filterDate.getMonth(), filterDate.getDate() - 1), 'YYYY-MM-DD')
    }
    this.eventList(filterPassEvent, 'passEvent')
  },
  methods: {
    handleClose () {},
    onDeleteImgGallery (img) {
      this.deleteImgSrc = img.thumb
      this.deleteImgTitle = img.description
      this.deleteImgId = img.id
    },
    setDataDeleteImg () {
      this.deleteImgSrc = null
      this.deleteImgTitle = null
      this.deleteImgId = null
    },
    resetDataEditImg () {
      this.formEditImageGallery.id = null
      this.editImageGallery = null
      this.formEditImageGallery.title = null
      this.formEditImageGallery.is_active = null
    },
    setDataEditImg (img) {
      this.formEditImageGallery.id = img.id
      this.formEditImageGallery.school = this.schoolId
      this.editImageGallery = img.src
      this.formEditImageGallery.title = img.description
      this.formEditImageGallery.is_active = true
    },
    closeModalPreviewImg () {
      if (this.deleteImgId != null || this.formEditImageGallery.id) {
        setTimeout(function () { document.querySelector('.cool-lightbox').click() }, 50)
      }
    },
    onSubmitDeleteImg () {
      this.deleteImgGallery(this.deleteImgId)
    },
    async geocodeAddress () {
      const address = this.school.school_address ? this.school.school_address : this.school.region_name
      try {
        const schoolAddress = encodeURI(address)
        const { data } = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${schoolAddress}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`
        )
        const geocodedLocation = data.results[0]
        this.location = {
          lat: geocodedLocation.geometry.location.lat,
          lng: geocodedLocation.geometry.location.lng
        }
      } catch (error) {
      }
    },
    eventActiveTab (e) {
      if (e === 1) {
        this.eventList(this.filter)
      } else if (e === 3) {
        this.schoolRatingCommentSummary()
      }
    },
    setReadOnly (el) {
      setTimeout(() => {
        el.target.removeAttribute('readonly')
      }, 80)
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    getSchoolDetail () {
      this.$store.dispatch('getSchoolDetail', { schoolId: this.ownerSchoolId })
        .then((response) => {
          this.school = response.data
          if (this.school.latitude === null || this.school.longitude === null) {
            this.geocodeAddress()
          }
          if (this.school.status === 1 && this.userProfile && this.userProfile.id === response.data.user) {
            this.$router.push({ name: 'schoolRegisterInformation' })
          }
          // set form data for update
          if (this.userProfile && this.userProfile.id === response.data.user) {
            this.ownerSchool = true
            for (const property in this.form) {
              if (property in response.data) {
                this.form[property] = response.data[property]
              }
              this.selectedRegion = { value: response.data.region, text: response.data.region_name }
            }
            const schoolId = response.data.id
            const schoolLevelOptions = []
            response.data.school_level.forEach(item => {
              schoolLevelOptions.push({
                school: schoolId,
                school_level: item.id,
                school_level_name: item.name
              })
            })
            if (this.form.school_level.length > 0) {
              if (this.form.school_level[0].level_type === 'Lifetime') {
                this.levelTypechecked = true
              } else {
                this.levelTypechecked = false
              }
            }
            this.school_level_options = schoolLevelOptions
            const schoolLevelAdditionalOptions = []
            response.data.school_level_additional.forEach(item => {
              schoolLevelAdditionalOptions.push({
                school: schoolId,
                school_level_additional: item.id,
                school_level_additional_name: item.name
              })
            })
            this.school_level_additional_options = schoolLevelAdditionalOptions
          } else {
            this.ownerSchool = false
          }
          this.loading = false
        })
        .catch((error) => {
          let messageError = ''
          if (error.response.status) {
            messageError = this.$t('Yoga school not found')
          } else {
            messageError = this.$t('Something wrong please try again')
          }
          this.$notify({
            group: 'error',
            title: '',
            text: messageError
          })
          this.loading = false
        })
    },
    submitForm () {
      this.$refs.form.submit()
    },
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.updateSchool()
        }
      })
    },
    updateSchool () {
      this.isLoading = true
      this.form.region = this.selectedRegion.value
      Api.updateSchool(this.form)
        .then((response) => {
          this.isLoading = false
          this.$notify({
            group: 'success',
            title: this.$t('Update yoga school'),
            text: this.$t('Successfully saved data')
          })
          this.$bvModal.hide('modal-update-school-profile')
          this.getSchoolDetail()
        })
        .catch((error) => {
          this.isLoading = false
          const responseError = error.response
          let textError = ''
          if (responseError) {
            textError = Object.values(responseError.data).join()
          } else {
            textError = this.$t('Unsuccessfully saved data')
          }
          this.$notify({
            group: 'error',
            title: this.$t('Update yoga school'),
            text: textError
          })
        })
    },
    searchRegion (searchText, loading) {
      this.searchText = searchText
      if (this.searchText) {
        this.regions = []
        loading(true)
        Api.searchRegion({ q: this.searchText }).then(response => {
          if (response.data.results) {
            response.data.results.forEach(region => {
              const url = region.url.split('/')
              this.regions.push({ value: url[url.length - 2], text: region.display_name })
            })
          }
          loading(false)
        }).catch(() => {
          loading(false)
        })
      }
    },
    getFormdata () {
      Api.shoolFormData().then(response => {
        if (response.data) {
          this.schoolFormData = response.data
        }
      })
    },
    handleUploading (form, xhr) {
      form.append('via', 'request')
    },
    handleCompleted (response, form, xhr) {
      if (response) {
        this.getSchoolDetail()
        this.$notify({
          group: 'success',
          title: this.$t('Upload avatar'),
          text: this.$t('Upload avatar successful')
        })
      } else {
        this.$notify({
          group: 'error',
          title: this.$t('Upload avatar'),
          text: this.$t('Upload avatar unsuccessful')
        })
      }
    },
    handlerError (message, type, xhr) {
      if (type === 'upload') {
        this.$notify({
          group: 'error',
          title: this.$t('Upload avatar'),
          text: this.$t('Upload avatar unsuccessful')
        })
      }
    },
    schoolComment (isReset) {
      const offset = (this.commentPage - 1) * this.commentLimitPerPage
      Api.schoolComment({ school: this.ownerSchoolId, limit: this.commentLimitPerPage, offset: offset }).then(response => {
        if (response.data.results) {
          if (isReset) {
            this.comments = []
          }
          response.data.results.forEach(value => {
            this.comments.push(value)
          })
          this.commentNextPage = response.data.next
        }
      }).catch(() => {
        this.commentNextPage = null
      })
    },
    mySchoolComment () {
      if (this.userProfile) {
        Api.schoolComment({ school: this.ownerSchoolId, user: this.userProfile.id }).then(response => {
          if (response.data.results) {
            this.myComment = { ...this.myComment, ...response.data.results[0] }
          }
        }).catch(() => {
          this.commentNextPage = null
        })
      }
    },
    viewMoreComment () {
      this.commentPage++
      this.schoolComment(false)
    },
    addComment (value) {
      this.isLoadingComment = true
      Api.addSchoolComment(value)
        .then((response) => {
          this.$notify({
            group: 'success',
            title: this.$t('Add comment'),
            text: this.$t('Successfully saved data')
          })
          this.getSchoolDetail()
          this.commentPage = 1
          this.schoolComment(true)
          this.mySchoolComment()
          this.schoolRatingCommentSummary()
          this.isLoadingComment = false
        })
        .catch((error) => {
          const responseError = error.response
          let textError = ''
          if (responseError) {
            textError = Object.values(responseError.data).join()
          } else {
            textError = this.$t('Unsuccessfully saved data')
          }
          this.$notify({
            group: 'error',
            title: this.$t('Add comment'),
            text: textError
          })
          this.isLoadingComment = false
        })
    },
    schoolGallery () {
      Api.schoolGallery({ school: this.ownerSchoolId }).then(response => {
        if (response.data.results) {
          this.schoolGalleries = response.data.results
          this.schoolGallerySlide = []
          this.schoolGalleries.forEach(img => {
            const slide = {
              id: img.id,
              src: img.image,
              thumb: img.image_small,
              description: img.title
            }
            this.schoolGallerySlide.push(slide)
          })
        }
      }).catch(() => {
        this.schoolGallerySlide = []
      })
    },
    deleteImgGallery (id) {
      Api.deleteSchoolGallery({ id: id }).then(response => {
        this.schoolGallery()
        this.$notify({
          group: 'success',
          title: this.$t('Delete Image'),
          text: this.$t('Successfully delete data')
        })
      }).catch(() => {
        this.$notify({
          group: 'error',
          title: this.$t('Delete Image'),
          text: this.$t('Unsuccessfully delete data')
        })
      }).finally(() => {
        this.$bvModal.hide('modal-delete-img-gallery')
      })
    },
    graduatedList (searchText, ordering) {
      const status = this.schoolId === this.viewSchoolId || this.viewSchoolId === null ? null : 2
      this.isLoadingGraduate = true
      const payload = {
        school: this.ownerSchoolId,
        search: searchText,
        ordering: ordering,
        page: this.currentPageGraduated,
        teacher__status: status,
        limit: this.itemPerpageGraduated,
        offset: (this.currentPageGraduated - 1) * this.itemPerpageGraduated
      }
      Api.graduated(payload).then(response => {
        if (response.data.results) {
          this.schoolGraduateds = response.data.results
          this.schoolGraduatedObj = response.data
          if (!searchText) {
            this.schoolGraduatedIntro = response.data.results.slice(0, 6)
          }
          this.isLoadingGraduate = false
        }
      }).catch(() => {
        this.isLoadingGraduate = false
      })
    },
    eventList (filter, tap) {
      this.isLoadingEvent = true
      var data = {
        school: this.ownerSchoolId
      }
      if (filter) {
        data = { ...data, ...filter }
      }
      Api.event(data).then(response => {
        if (response.data.results) {
          if (tap === 'intro') {
            this.eventsIntro = response.data.results
          } else if (tap === 'passEvent') {
            this.pastEvents = response.data.results
          } else {
            this.events = response.data.results
          }
          this.isLoadingEvent = false
        }
      }).catch(() => {
        this.isLoadingEvent = false
      })
    },
    onChangeMonthEvent (dataFilter) {
      const filterDate = new Date(dataFilter.date)
      const currentDate = new Date()
      var firstDay = null
      if (currentDate.getMonth() === filterDate.getMonth()) {
        firstDay = new Date()
      } else {
        firstDay = new Date(filterDate.getFullYear(), filterDate.getMonth(), 1)
      }
      var lastDay = new Date(filterDate.getFullYear(), filterDate.getMonth() + 1, 0)
      const filter = {
        start_date__gte: this.$options.filters.dateFormat(firstDay, 'YYYY-MM-DD'),
        end_date__lte: this.$options.filters.dateFormat(lastDay, 'YYYY-MM-DD')
      }
      this.eventList(filter)
    },
    schoolRatingCommentSummary () {
      Api.schoolRatingCommentSummary({ schoolId: this.ownerSchoolId }).then(response => {
        if (response.data) {
          this.ratingCommentSummary = response.data
        }
      }).catch(() => {})
    },
    onSearchGraduate (val) {
      this.currentPageGraduated = 1
      this.searchTextGraduated = val.searchText
      this.orderingGraduated = val.ordering
      this.graduatedList(val.searchText, val.ordering)
    },
    addGraduate (forms) {
      this.isLoadingFormAddGraduate = true
      forms.forEach((form, index) => {
        Api.addGraduated(form).then(response => {
          if (response.data) {
            this.schoolGraduateds.push(response.data)
            if (index === (forms.length - 1)) {
              this.closeModalFormAddGraduate = true
              this.isLoadingFormAddGraduate = false
            }
            this.$notify({
              group: 'success',
              title: this.$t('Add graduate'),
              text: this.$t('Successfully saved data') + ' ' + form.first_name + ' ' + (form.last_name || '')
            })
          }
        }).catch(() => {
          this.$notify({
            group: 'error',
            title: this.$t('Add graduate'),
            text: this.$t('Unsuccessfully saved data') + ' ' + form.first_name + ' ' + (form.last_name || '')
          })
          if (index === (forms.length - 1)) {
            this.isLoadingFormAddGraduate = false
          }
        })
      })
    },
    editGraduate (form) {
      this.isLoadingFormAddGraduate = true
      Api.editGraduated(form).then(response => {
        if (response.data) {
          const index = this.schoolGraduateds.findIndex((graduate) => graduate.id === form.id)
          this.schoolGraduateds.splice(index, 1, response.data)
          this.closeModalFormAddGraduate = true
          this.$notify({
            group: 'success',
            title: this.$t('Edit graduate'),
            text: this.$t('Successfully saved data')
          })
          this.isLoadingFormAddGraduate = false
        }
      }).catch(() => {
        this.$notify({
          group: 'error',
          title: this.$t('Edit graduate'),
          text: this.$t('Unsuccessfully saved data')
        })
        this.isLoadingFormAddGraduate = false
      })
    },
    onDeleteGraduate (form) {
      Api.deleteGraduated(form).then(response => {
        const index = this.schoolGraduateds.findIndex((graduate) => graduate.id === form.id)
        this.schoolGraduateds.splice(index, 1)
        this.$notify({
          group: 'success',
          title: this.$t('Delete graduate'),
          text: this.$t('Successfully delete data')
        })
      }).catch(() => {
        this.$notify({
          group: 'error',
          title: this.$t('Delete graduate'),
          text: this.$t('Unsuccessfully delete data')
        })
      })
    },
    onImageGalleryChange (event) {
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          if (e) {
            this.imageGallery = e.target.result
          }
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },
    onEditImageGalleryChange (event) {
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          if (e) {
            this.editImageGallery = e.target.result
          }
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onSubmitImageGallery () {
      this.$refs.formGallery.validate().then(result => {
        if (result) {
          this.isLoadingImageGallery = true
          this.formImageGallery.school = this.ownerSchoolId
          Api.addImageGallery(this.formImageGallery).then(response => {
            if (response.data) {
              this.schoolGalleries.push(response.data)
              const slide = {
                id: response.data.id,
                src: response.data.image,
                thumb: response.data.image_small,
                description: response.data.title
              }
              this.resetFormImgGallery()
              this.isLoadingImageGallery = false
              this.schoolGallerySlide.push(slide)
              this.$notify({
                group: 'success',
                title: this.$t('Add Image Gallery'),
                text: this.$t('Successfully saved data')
              })
            }
            this.$refs['modal-add-img-gallery'].hide()
          }).catch(() => {
            this.isLoadingImageGallery = false
            this.$notify({
              group: 'error',
              title: this.$t('Add Image Gallery'),
              text: this.$t('Unsuccessfully saved data')
            })
            this.$refs['modal-add-img-gallery'].hide()
          })
        }
      })
    },
    onSubmitEditImageGallery () {
      this.$refs.formGallery.validate().then(result => {
        if (result) {
          this.isLoadingEditImageGallery = true
          this.formEditImageGallery.school = this.ownerSchoolId
          Api.updateSchoolGallery(this.formEditImageGallery).then(response => {
            this.schoolGallery()
            if (response.data) {
              this.$notify({
                group: 'success',
                title: this.$t('Update Image Gallery'),
                text: this.$t('Successfully update data')
              })
            }
            this.isLoadingEditImageGallery = false
            this.$refs['modal-edit-img-gallery'].hide()
          }).catch(() => {
            this.isLoadingEditImageGallery = false
            this.$notify({
              group: 'error',
              title: this.$t('Update Image Gallery'),
              text: this.$t('Unsuccessfully update data')
            })
            this.$refs['modal-add-img-gallery'].hide()
          })
        }
      })
    },
    resetFormImgGallery () {
      this.imageGallery = null
      this.$nextTick(() => {
        this.formImageGallery.image = null
        this.formImageGallery.title = null
        this.$refs.formImageGallery.reset()
      })
    },
    onDeleteEvent (form) {
      Api.deleteEvent(form).then(response => {
        if (form.isPast) {
          const index = this.pastEvents.findIndex((event) => event.id === form.id)
          this.pastEvents.splice(index, 1)
        } else {
          const index = this.events.findIndex((event) => event.id === form.id)
          this.events.splice(index, 1)
        }
        this.$notify({
          group: 'success',
          title: this.$t('Delete event'),
          text: this.$t('Successfully delete data')
        })
      }).catch(() => {
        this.$notify({
          group: 'error',
          title: this.$t('Delete event'),
          text: this.$t('Unsuccessfully delete data')
        })
      })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
  .btn-social-leftbar {
    height:40px;
    width:40px;
  }
  .icon-link {
    background-color:#841F26;
  }
  .icon-facebook {
    background-color:#3b5998;
  }
  .icon-instragram {
    background-color:#3f729b;
  }
  .icon-twitter {
    background-color: rgb(83 167 232);
  }
  .box-graduate {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 10px #0000000D;
    border-radius: 10px;
  }
  .img-wrapper{
    position: relative;
    overflow:hidden;
    height:249px;
    width: 166px;
  }
  .img-wrapper img{
      position: absolute;
      top:-100%; left:0; right: 0; bottom:-100%;
      margin: auto;
  }
  .img-wrapper .overlay-text {
    position: absolute;
    background: transparent linear-gradient(rgba(255, 255, 255, 0.007), rgba(0, 0, 0, 0.719));
    height: 68px;
    width: 100%;
    bottom: 0;
  }
  .school-profile .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-color: #ffffff #ffffff #F04B4C;
  }
  .school-profile .nav-link {
    border: 2px solid transparent;
    color: #9A9A9A;
    font-weight: bold;
  }
  .btn-img-edit-profile {
    font-size: 2.5rem;
    float: right;
    position: absolute;
    margin-left: 40px;
  }
  .btn-img-edit-cover-school {
    width: 100%;
    margin-top: 260px;
    font-size: 2.5rem;
    position: absolute;
    margin-right: 0px;
  }
  .btn-img-edit-cover-school button {
    float: right;
    margin-right: 20px;
  }
  .btn-img-edit-profile:hover, .i-con-avatar:hover, .add-img-gallery:hover, .img-school-gallery:hover{
    cursor: pointer;
  }
  .add-img-gallery{
    background-color: #FAFAFA;
    border: 1px solid #DBDBDB;
    height: auto;
    max-height: 345px !important;
  }
  .modal-header, .modal-footer {
    border-bottom: 0px !important;
  }
  .modal-footer {
    border-top: 0px !important;
  }
  .v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
  }

  .v-select .dropdown li:last-child {
    border-bottom: none;
  }

  .v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
  }

  .v-select .dropdown-menu .active > a {
    color: #fff;
  }
  .vs__dropdown-menu > li {
    padding: 5px !important;
    padding-left: 7px !important;
    border-bottom: 1px solid #f6f8fa;
  }
  .avatar-cropper-btn:hover {
    background-color: #3B6098 !important;
  }
  .pswp__caption__center {
    text-align: center !important;
  }
  .my-gallery {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .my-gallery figure{
    margin: 0px 0px 15px 15px !important;
  }
  .my-gallery img{
    width: 77px;
    height: 77px;
    border-radius: 5px;
  }
  .image {
    width: 77px;
    height: 77px;
    border-radius: 5px;
    margin: 0px 0px 5px 0px !important;
    background-position: center !important;
  }
  .btn-edit-gallery button {
    padding: 0px;
  }
</style>
