<template>
  <div>
    <ShareNetwork
      class="ml-1 mr-1"
      v-for="network in networks"
      :network="network.network"
      :key="network.network"
      :url="sharing.url"
      :title="sharing.title"
      :description="sharing.description"
      :quote="sharing.quote"
      :hashtags="sharing.hashtags"
      :twitterUser="sharing.twitterUser"
    >
    <b-button :class="'font-weight-bold rounded-circle btn-social border-0 ' + network.classType"><font-awesome-icon :icon="['fab', network.icon]"/></b-button>
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: 'SocialSharing',
  props: ['id', 'metatag', 'type'],
  data () {
    return {
      sharing: {
        url: this.url(),
        title: '',
        description: '',
        quote: '',
        hashtags: '',
        twitterUser: ''
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'facebook-f', classType: 'icon-facebook' },
        { network: 'line', name: 'Line', icon: 'line', classType: 'icon-line' },
        { network: 'tumblr', name: 'Tumblr', icon: 'tumblr', classType: 'icon-tumblr' },
        { network: 'twitter', name: 'Twitter', icon: 'twitter', classType: 'icon-twitter' }
      ]
    }
  },
  mounted () {
    if (this.metatag) {
      this.sharing = { ...this.sharing, ...this.metatag }
    }
  },
  methods: {
    url () {
      if (this.type === 'event') {
        return window.location.protocol + '//' + window.location.host + '/event/' + this.id
      } else if (this.type === 'teacher') {
        return window.location.protocol + '//' + window.location.host + '/teacher/profile/' + this.id
      } else {
        return window.location.protocol + '//' + window.location.host + '/school/profile/' + this.id
      }
    }
  }
}
</script>

<style scoped>
 .btn-social {
    height:40px;
    width:40px;
}
.icon-facebook {
    background-color:#3b5998;
}
.icon-line {
    background-color: #00C300;
}
.icon-tumblr {
    background-color: #01579B;
}
.icon-twitter {
    background-color: rgb(83 167 232);
}
</style>
